import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { resettableReducer } from 'reduxsauce';

import { reducer as affiliate } from './affiliate';
import { reducer as alert } from './alert';
import { reducer as app } from './app';
import { reducer as autocomplete } from './autocomplete';
import { reducer as banners } from './banners';
import { reducer as bestDeals } from './bestDeals';
import { reducer as bookingConfirmation } from './bookingConfirmation';
import { reducer as cache } from './cache';
import { reducer as checkout } from './checkout';
import { reducer as city } from './city';
import { reducer as contest } from './contest';
import { reducer as cookieConsent } from './cookiesConsent';
import { reducer as country } from './country';
import { reducer as courseAlerts } from './courseAlerts';
import { reducer as courseDetail } from './courseDetail';
import { reducer as courseMembership } from './courseMembership';
import { reducer as courseReviews } from './courseReviews';
import { reducer as courseSuggestions } from './courseSuggestions';
import { reducer as createCreditCard } from './createCreditCard';
import { reducer as creditCards } from './paymentMethods';
import { reducer as dealDetail } from './dealDetail';
import { reducer as explore } from './explore';
import { reducer as faqs } from './faqs';
import { reducer as favoriteCities } from './favoriteCities';
import { reducer as favoriteCourses } from './favoriteCourses';
import { reducer as feedback } from './feedback';
import { reducer as flow } from './flow';
import { reducer as guest } from './guest';
import { reducer as invite } from './invite';
import { reducer as headerMenuSmall } from './headerMenuSmall';
import { reducer as location } from './location';
import { reducer as marketingBanner } from './marketingBanner';
import { reducer as membership } from './membership';
import { reducer as membershipDetails } from './membershipDetails';
import { reducer as navbar } from './navbar';
import { reducer as networkMembership } from './networkMembership';
import { reducer as offers } from './offers';
import { reducer as page } from './page';
import { reducer as partnerOffers } from './partnerOffers';
import { reducer as passwordChange } from './passwordChange';
import { reducer as priceAlerts } from './priceAlerts';
import { reducer as profile } from './profile';
import { reducer as rateTypes } from './rateTypes';
import { reducer as registrations } from './registrations';
import { reducer as requestResetPassword } from './requestResetPassword';
import { reducer as reservations } from './reservations';
import { reducer as resetPassword } from './resetPassword';
import { reducer as rewards } from './rewards';
import { reducer as searchCourses } from './searchCourses';
import { reducer as searchDeals } from './searchDeals';
import { reducer as searchFilters } from './searchFilters';
import { reducer as searchParams } from './searchParams';
import { reducer as selectProvider } from './selectProvider';
import { reducer as session } from './session';
import { reducer as site } from './site';
import { reducer as subscriptions } from './subscriptions';
import { reducer as support } from './support';
import { reducer as timeSlots } from './timeSlots';
import { reducer as unlock } from './unlock';
import { reducer as userAgreements } from './userAgreements';
import { reducer as welcome } from './welcome';

const resettable = resettableReducer('RESET');

// Please keep this list alphabetically sorted
const rootReducer = (history) => combineReducers({
  affiliate,
  alert,
  app,
  autocomplete,
  banners: resettable(banners),
  bestDeals: resettable(bestDeals),
  bookingConfirmation,
  cache,
  checkout,
  city: resettable(city),
  contest,
  cookieConsent,
  country,
  courseAlerts,
  courseDetail: resettable(courseDetail),
  courseMembership,
  courseReviews: resettable(courseReviews),
  courseSuggestions,
  createCreditCard,
  creditCards,
  dealDetail,
  explore,
  faqs,
  favoriteCities,
  favoriteCourses,
  feedback,
  flow,
  guest,
  invite,
  location,
  marketingBanner,
  membership,
  membershipDetails,
  networkMembership,
  offers,
  page,
  partnerOffers,
  passwordChange,
  priceAlerts,
  profile,
  rateTypes: resettable(rateTypes),
  registrations,
  requestResetPassword,
  reservations: resettable(reservations),
  resetPassword,
  rewards,
  router: connectRouter(history),
  search: combineReducers({
    searchDeals, searchFilters, searchParams, searchCourses: resettable(searchCourses),
  }),
  selectProvider: resettable(selectProvider),
  session,
  site,
  subscriptions,
  support,
  timeSlots: resettable(timeSlots),
  ui: combineReducers({ headerMenuSmall, navbar: resettable(navbar) }),
  unlock: resettable(unlock),
  userAgreements,
  welcome,
});

export default rootReducer;
