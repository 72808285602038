import { put, select } from 'redux-saga/effects';
import CacheActions from 'reducers/cache';

const isCacheExpired = ({ timeStamp, ttl }) => (Date.now() - timeStamp) > ttl;

export function* getCache(name) {
  const { local } = yield select((state) => state.cache);
  if (local[name] && isCacheExpired(local[name])) {
    yield put(CacheActions.invalidateCache(name));
  }

  return local[name] ?? { data: null, stale: null, timeStamp: null };
}

export default [];
