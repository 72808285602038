import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import qs from 'qs';

import CourseDetailActions, { CourseDetailTypes } from 'reducers/courseDetail';
import CacheActions from 'reducers/cache';
import { getCache } from 'sagas/cache';
import CourseDetailApi from 'apis/supremeGolfApi/CourseDetailApi';

import DateHelper from 'utils/dateHelper';

export function* requestCourseDetailHandler({ courseId, iterableTracking }) {
  try {
    const cachedData = yield* getCache(`course-detail-${courseId}`);
    let courseDetail = cachedData.data;

    if (cachedData.stale || !courseDetail) {
      const search = yield select(({ router }) => router.location.search);
      const { date } = qs.parse(search, { ignoreQueryPrefix: true });
      courseDetail = yield call(
        CourseDetailApi.getCourseDetail,
        courseId,
        DateHelper.formatDate(date, 'YYYY/MM/DD'),
        iterableTracking,
      );
      yield put(CacheActions.setCache(`course-detail-${courseId}`, courseDetail));
    }

    yield put(CourseDetailActions.getCourseDetailDone(courseDetail));
  } catch (error) {
    yield put(CourseDetailActions.getCourseDetailError(error.message));
  }
}

export function* requestCourseScorecardHandler({ courseId }) {
  try {
    const courseScorecard = yield call(CourseDetailApi.getCourseScorecard, courseId);
    if (courseScorecard) {
      yield put(CourseDetailActions.getCourseScorecardDone(courseScorecard));
    }
  } catch (error) {
    const errorMessage = error?.response?.data?.error || error?.message;
    yield put(CourseDetailActions.getCourseScorecardError(errorMessage));
  }
}

function* requestCourseDetailWatcher() {
  yield takeLatest(CourseDetailTypes.GET_COURSE_DETAIL, requestCourseDetailHandler);
}

function* requestCourseDetailSilentWatcher() {
  yield takeLatest(CourseDetailTypes.GET_COURSE_DETAIL_SILENT, requestCourseDetailHandler);
}

function* requestCourseScoreCardWatcher() {
  yield takeLatest(CourseDetailTypes.GET_COURSE_SCORECARD, requestCourseScorecardHandler);
}

export default [
  requestCourseDetailWatcher,
  requestCourseDetailSilentWatcher,
  requestCourseScoreCardWatcher,
];
