import ls from 'local-storage';
import qs from 'qs';
import humps from 'humps';

class LocalStorage {
  static getValue(key) {
    return ls.get(key);
  }

  static setValue(key, value) {
    return new Promise((resolve) => {
      resolve(ls.set(key, value));
    });
  }

  static getUtmParams(asQuery = false) {
    const utmTimestamp = LocalStorage.getValue('utm_timestamp');
    if (utmTimestamp) {
      const daysSinceUtmWasSet = ((new Date()) - (new Date(utmTimestamp))) / (1000 * 3600 * 24);
      if (daysSinceUtmWasSet >= 90) {
        this.removeUtmParams();
        return {};
      }
    }

    const utmChannel = LocalStorage.getValue('utm_channel');
    const utmSource = LocalStorage.getValue('utm_source');
    const utmMedium = LocalStorage.getValue('utm_medium');
    const utmCampaign = LocalStorage.getValue('utm_campaign');
    const utmAdgroup = LocalStorage.getValue('utm_adgroup');
    const utmKeyword = LocalStorage.getValue('utm_keyword');
    const utmCreative = LocalStorage.getValue('utm_creative');
    const utmDevice = LocalStorage.getValue('utm_device');
    const utmNetwork = LocalStorage.getValue('utm_network');
    const utmLocation = LocalStorage.getValue('utm_location');
    const utmContent = LocalStorage.getValue('utm_content');
    const gclid = LocalStorage.getValue('gclid');
    const msclkid = LocalStorage.getValue('msclkid');

    const utmParams = {
      utmChannel,
      utmSource,
      utmMedium,
      utmCampaign,
      utmAdgroup,
      utmKeyword,
      utmCreative,
      utmDevice,
      utmNetwork,
      utmLocation,
      utmContent,
      utmTimestamp,
      gclid,
      msclkid,
    };

    if (asQuery) {
      const utmQuery = qs.stringify(humps.decamelizeKeys(utmParams), { skipNulls: true });
      return utmQuery;
    }

    return utmParams;
  }

  static getRwgParams(forGolfNow = false) {
    const rwgTimestamp = LocalStorage.getValue('rwg_timestamp');
    const rwgToken = LocalStorage.getValue('rwg_token');
    const rwgCourseId = LocalStorage.getValue('rwg_course_id');
    const rwgTokenCreatedAt = new Date(rwgTimestamp) / 1000;

    if (rwgTimestamp) {
      const daysSinceUtmWasSet = ((new Date()) - (new Date(rwgTimestamp))) / (1000 * 3600 * 24);

      if (daysSinceUtmWasSet >= 30) {
        LocalStorage.removeKey('rwg_token');
        LocalStorage.removeKey('rwg_timestamp');
        LocalStorage.removeKey('rwg_course_id');
        return null;
      }
    }

    const rwgParams = { rwgToken, rwgCourseId, rwgTokenCreatedAt };
    if (forGolfNow && rwgParams?.rwgCourseId) delete rwgParams.rwgCourseId;

    return rwgToken ? rwgParams : {};
  }

  static getToken() {
    return LocalStorage.getValue('X-Api-User-Token');
  }

  static setToken(token) {
    return LocalStorage.setValue('X-Api-User-Token', token);
  }

  static setSessionToken(token) {
    return LocalStorage.setValue('X-Api-Session', token);
  }

  static getSessionToken() {
    return LocalStorage.getValue('X-Api-Session');
  }

  static hasToken() {
    return new Promise((resolve) => {
      const token = ls.get('X-Api-User-Token');
      resolve(!!token);
    });
  }

  static removeKey(key) {
    return ls.remove(key);
  }

  static removeUtmParams() {
    ls.remove('utm_channel');
    ls.remove('utm_source');
    ls.remove('utm_medium');
    ls.remove('utm_campaign');
    ls.remove('utm_adgroup');
    ls.remove('utm_keyword');
    ls.remove('utm_creative');
    ls.remove('utm_device');
    ls.remove('utm_network');
    ls.remove('utm_location');
    ls.remove('utm_content');
    ls.remove('utm_timestamp');
    ls.remove('gclid');
    ls.remove('msclkid');
  }
}

export default LocalStorage;
