import { MICROSITES } from 'utils/constants';

export const currentMicrosite = MICROSITES.find(
  (site) => site.name === process.env.REACT_APP_NAME,
).shortName;

export const M = {
  SG: currentMicrosite === 'sg',
  BT: currentMicrosite === 'bt',
  GD: currentMicrosite === 'gd',
  GB: currentMicrosite === 'gb',
  FOH: currentMicrosite === 'foh',
  TWC: currentMicrosite === 'twc',
};

export default (config) => {
  const common = config.common || '';
  const siteStyle = config[currentMicrosite] || config.default;
  return `${common}${siteStyle ? ` ${siteStyle}` : ''}`;
};
