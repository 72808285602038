import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { GOLFBOOK, TEEOFF, GOLFNOW } from 'utils/constants';

const { Types, Creators } = createActions({
  getProviders: ['courseId', 'params'],
  getProvidersDone: ['teeTimeProviders'],
  getProvidersError: ['message'],
  setIsLoadingProviderList: ['isLoadingProviderList'],
});

export const SelectProviderTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  teeTimeProviders: null,
  isLoading: false,
  isLoadingProviderList: false,
  isDone: false,
  isError: false,
  errorMessage: '',
});

export const getSortedProviders = (providers) => {
  if (!providers || providers.length <= 1) return providers;

  const instant = providers.filter((p) => p.instantBooking);
  const rest = providers.filter((p) => !p.instantBooking);
  const golfbook = rest.filter((p) => p.provider.name === GOLFBOOK);
  const golfnow = rest.filter((p) => p.provider.name === GOLFNOW);
  const teeOff = rest.filter((p) => p.provider.name === TEEOFF);
  const last = rest.filter((p) => p.provider.name !== GOLFBOOK
  && p.provider.name !== GOLFNOW && p.provider.name !== TEEOFF);

  return [...instant, ...golfbook, ...golfnow, ...teeOff, ...last];
};

const getProviders = (state) => state.merge({
  isLoading: true,
  isError: false,
  isDone: false,
});

const getProvidersDone = (state, { teeTimeProviders }) => state.merge({
  isLoading: false,
  isError: false,
  teeTimeProviders,
  isDone: true,
});

const getProvidersError = (state, { message }) => state.merge({
  isLoading: false,
  isError: false,
  errorMessage: message,
  isDone: true,
});

const setIsLoadingProviderList = (state, { isLoadingProviderList }) => state.merge({
  isLoadingProviderList,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PROVIDERS]: getProviders,
  [Types.GET_PROVIDERS_DONE]: getProvidersDone,
  [Types.GET_PROVIDERS_ERROR]: getProvidersError,
  [Types.SET_IS_LOADING_PROVIDER_LIST]: setIsLoadingProviderList,
});
