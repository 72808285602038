import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { push } from 'react-router-redux';

import GuestApi from 'apis/supremeGolfApi/GuestApi';
import GuestActions, { GuestTypes } from 'reducers/guest';
import CheckoutActions from 'reducers/checkout';
import FlowActions from 'reducers/flow';
import TrackingActions from 'reducers/tracking';
import { teeTimeFailure } from 'sagas/checkout';

import {
  BOOKING_CONFIRMATION, CHECKOUT, SIGN_IN, TEE_TIME_ERROR,
} from 'utils/routes';
import { GUEST_BLOCKED_MESSAGE, INTERNAL_CHECKOUT_ERROR_MESSAGES, UNAVAILABLE_TEE_TIMES_ERROR_CODE } from 'utils/constants';
import DateHelper from 'utils/dateHelper';
import { matchRoute } from 'utils/routeHelpers';

export function* trackGuestEvent({ event, teeTime, otherParams = {} }) {
  let course = yield select((state) => state.courseDetail.course);
  const { query } = yield select((state) => state.router.location);
  const extraParams = { ...otherParams };
  const teeTimeExtraParams = teeTime?.extraParams || {};
  if (extraParams?.course) course = extraParams.course;
  delete extraParams.course;

  const courseParams = {
    course_name: course?.name,
    course_id: course?.id,
    course_slug: course?.slug,
    course_address: course?.formattedAddress,
    course_zip_code: course?.addressZipcode,
  };

  const teeTimeParams = teeTime?.teeTimeId ? {
    tee_time_id: teeTime.teeTimeId,
    timeSlot: DateHelper.formatDate(teeTime.teeOffAtLocal, DateHelper.TIME_SLOT, true),
    cart: teeTime.isRiding,
    holes: teeTime.numHoles,
    players: teeTime.players,
    rateTypeLabel: teeTime.rateTypeLabel,
    ...teeTimeExtraParams,
  } : {};

  const params = {
    ...courseParams,
    dateOfPlay: teeTime?.teeOffAtLocal ? DateHelper.formatDate(teeTime.teeOffAtLocal, 'yyyy-MM-DD') : null,
    ...teeTimeParams,
    ...extraParams,
    platform: query?.isAppClipUrl === 'true' ? 'app_clip' : 'web',
  };
  yield put(TrackingActions.trackGAEvent(event, params));
}

export function* trackGuestCheckoutEvent({ name, screenName, otherParams }) {
  const { guestInfo } = yield select((state) => state.guest);
  let preparedTeeTime = yield select((state) => state.checkout.preparedTeeTime);
  if (otherParams?.preparedTeeTime) preparedTeeTime = otherParams.preparedTeeTime;

  const params = {
    teeTime: {
      ...preparedTeeTime.teeTime,
      teeTimeId: preparedTeeTime?.teeTimeId,
      players: preparedTeeTime?.qty,
    },
    other: {
      ...guestInfo,
      screenName,
      signInMode: 'guest',
      ...otherParams,
    },
  };

  yield put(GuestActions.trackGuestEvent({
    name,
    label: name.replaceAll('_', ' '),
    category: `${screenName} event`,
  }, params.teeTime, params.other));
}

export function* trackGuestAbandon({ params }) {
  const { location } = yield select((state) => state.router);
  const excludedRoutes = [CHECKOUT, BOOKING_CONFIRMATION, SIGN_IN];

  if (excludedRoutes.every((item) => !matchRoute(item, location.pathname))) {
    yield* trackGuestCheckoutEvent({
      name: 'guest_checkout_Abandoned',
      screenName: 'Guest checkout',
      otherParams: params,
    });
  }
}

export function* requestValidateGuestEmail({ email, cb }) {
  try {
    yield call(GuestApi.validateEmail, { email });
    yield put(GuestActions.validateGuestEmailSuccess());
    cb();
  } catch (error) {
    const errorMessage = error?.response?.data?.error ?? error?.message;
    yield put(GuestActions.validateGuestEmailError(errorMessage));
    if (cb) cb(errorMessage);
  }
}

export function* createGuestCreditCard({ token, gRecaptchaResponseData }) {
  try {
    const data = yield call(GuestApi.addGuestCreditCard, token, gRecaptchaResponseData);
    const { creditCard } = data;
    yield put(GuestActions.createGuestCreditCardDone(creditCard));
  } catch (error) {
    const errorMessage = error?.response?.data?.message ?? error?.message;
    yield put(GuestActions.createGuestCreditCardError(errorMessage));
  }
}

export function* makeGuestPayment({ paymentType, paymentData, cb }) {
  try {
    const authToken = yield select(({ flow }) => flow?.checkout?.authToken);
    const creditCard = yield select(({ checkout }) => checkout.creditCard);

    const data = yield call(GuestApi.makeGuestPayment, {
      paymentType,
      paymentData: { ...paymentData, creditCardAccessToken: creditCard?.accessToken },
      authToken,
    });

    if (cb) cb({ success: true });

    yield* trackGuestCheckoutEvent({
      name: 'guest_booking_success',
      screenName: 'Guest checkout',
    });

    yield put(GuestActions.makeGuestPaymentDone(data.receipt));
    yield put(CheckoutActions.resetCheckout());
    yield put(FlowActions.resetFlow());
    const { receipt: { accessToken: guestToken, offer: { teeTimeReservationId } } } = data;
    const { query } = yield select((state) => state.router.location);

    const guestQs = `${query?.isAppClipUrl === 'true' ? '&webView=true&isAppClipUrl=true' : ''}${query?.isAnchoredTeeTime === 'true' ? '&isAnchoredTeeTime=true' : ''}`;
    yield put(push(`${BOOKING_CONFIRMATION.replace(':reservationId', teeTimeReservationId)}?accessToken=${guestToken}${guestQs}`));
  } catch (error) {
    const {
      response: {
        data: {
          error: explanatoryError,
          isReservationUnconfirmed: isRecoverable,
          reservationUrl,
          isPrepaidOnProvider,
        },
      },
    } = error || {};
    if (cb) cb({ success: false });
    yield* trackGuestCheckoutEvent({
      name: 'guest_booking_failed',
      screenName: 'Guest checkout',
    });

    const errorMessage = explanatoryError || error.message;
    yield put(GuestActions.makeGuestPaymentError(errorMessage));

    if (!isRecoverable && isPrepaidOnProvider) {
      yield* teeTimeFailure(TEE_TIME_ERROR, { reservationUrl });
    } else if (!isRecoverable && INTERNAL_CHECKOUT_ERROR_MESSAGES.includes(errorMessage)) {
      yield* teeTimeFailure(UNAVAILABLE_TEE_TIMES_ERROR_CODE, { errorMessage });
    } else if (!isRecoverable && errorMessage !== GUEST_BLOCKED_MESSAGE) {
      yield* teeTimeFailure(UNAVAILABLE_TEE_TIMES_ERROR_CODE);
    }
  }
}

function* requestValidateGuestEmailWatcher() {
  yield takeLatest(GuestTypes.VALIDATE_GUEST_EMAIL, requestValidateGuestEmail);
}

function* createGuestCreditCardWatcher() {
  yield takeLatest(GuestTypes.CREATE_GUEST_CREDIT_CARD, createGuestCreditCard);
}

function* makeGuestPaymentWatcher() {
  yield takeLatest(GuestTypes.MAKE_GUEST_PAYMENT, makeGuestPayment);
}

function* trackGuestEventWatcher() {
  yield takeLatest(GuestTypes.TRACK_GUEST_EVENT, trackGuestEvent);
}

function* trackGuestCheckoutEventWatcher() {
  yield takeLatest(GuestTypes.TRACK_GUEST_CHECKOUT_EVENT, trackGuestCheckoutEvent);
}

function* trackGuestAbandonWatcher() {
  yield takeLatest(GuestTypes.TRACK_GUEST_ABANDON, trackGuestAbandon);
}

export default [
  requestValidateGuestEmailWatcher,
  createGuestCreditCardWatcher,
  makeGuestPaymentWatcher,
  trackGuestEventWatcher,
  trackGuestAbandonWatcher,
  trackGuestCheckoutEventWatcher,
];
