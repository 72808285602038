export const EXPLORE_PREFIX = '/explore';
export const MY_SG_PREFIX = '/my-sg';
export const MY_ACCOUNT_PREFIX = '/my-account';
export const NETWORK_MEMBERSHIP = '/membership';

// Explore
export const CITY_SEARCH_RESULT = `${EXPLORE_PREFIX}/:country/:state/:city`;
export const COURSE_SEARCH_RESULT = `${EXPLORE_PREFIX}/:countryId/:stateId/:cityId/:courseId`;
export const EXPLORE_COUNTRY = `${EXPLORE_PREFIX}/:country`;
export const EXPLORE_STATE = `${EXPLORE_PREFIX}/:country/:state`;
export const COURSE_DETAILS = `${EXPLORE_PREFIX}/:countryId/:stateId/:cityId/:courseId/details`;

// Course Operators
export const FREE_MARKETPLACE = '/free-marketplace';
export const FREE_MARKETPLACE_SIGNUP_DETAILS = '/free-marketplace-signup-details';
export const VIP_PERKS = '/vip-perks';
export const GOLF_COURSE_RESOURCES = '/golf-course-resources';

// Course Reviews
export const COURSE_REVIEWS = `${COURSE_SEARCH_RESULT}/reviews`;
export const COURSE_REVIEW = `${COURSE_REVIEWS}/:reviewId`;
export const NEW_COURSE_REVIEW = `${COURSE_SEARCH_RESULT}/review/new`;

// Sections
export const SECTION_COURSES = '/sections/:courseSectionSlug';

// Leaves routes
export const ABOUT = '/about';
export const BOOKING_CONFIRMATION = '/booking-confirmations/:reservationId';
export const BOOKING_CONFIRMATION_AT = '/bookingConfirmation/at/:courseId';
export const BOOKINGS_INVITE = '/bookings/invite/:inviteId';
export const BOOKING_OPTIONS = '/booking-options';
export const CHECKOUT = '/checkout/at/:courseId';
export const CANCELLATION_POLICY = '/faqs#refund-cancellations';
export const DEAL = '/deals/:id';
export const DEALS = '/deals';
export const DISCLAIMERS = '/disclaimers';
export const FAQS = '/faqs';
export const GOLF_TIME_PROMOTIONS = '/golf-time-promotions';
export const HOME = '/';
export const MEMBERSHIP_UPSELL = '/membership-page/at/:courseId';
export const NETWORK_MEMBERSHIP_BENEFITS = '/network-membership-benefits/:courseId';
export const NOT_FOUND = '/not-found';
export const POLICY_AND_TERMS = '/policy-and-terms';
export const PRIVACY_POLICY = '/privacy-policy';
export const RATE_TYPES = '/rate-types/at/:courseId';
export const REQUEST_RESET_PASSWORD = '/request-reset-password';
export const RESET_PASSWORD = '/reset-password';
export const VERIFY_CUSTOMER_DETAILS = '/login-verifycustomerdetails';
export const SELECT_PROVIDER = '/select-provider/at/:courseId';
export const SG_CLUB = '/sg-club';
export const SG_CLUB_TERMS_AND_CONDITIONS = '/sg-club-terms-and-conditions';
export const MEMBERSHIP_TERMS_AND_CONDITIONS = '/membership-terms-and-conditions';
export const SIGN_IN = '/sign-in';
export const SIGN_UP = '/sign-up';
export const SUPPORT = '/support';
export const TEE_TIME_ERROR = '/tee-time-error';
export const TEE_TIME_NOT_AVAILABLE = '/tee-time-not-available';
export const TEE_TIME_NOT_ALLOWED = '/tee-time-not-allowed';
export const BOOKING_LIMIT_REACHED = '/booking-limit-reached';
export const UNDEFINED_ROUTE = '#';
export const TERMS_AND_CONDITIONS = '/terms-and-conditions';
export const UNLOCK_ACCOUNT_FAILED = '/unlock-account-failed';
export const UNLOCK = '/unlock';
export const UNSUBSCRIBE = '/unsubscribe/:mailingList';
export const UNSUBSCRIBE_PRE_USERS = '/deals/unsubscribe/:mailingList';
export const REWARDS_TERMS_AND_CONDITIONS = '/rewards-terms-and-conditions';
export const WELCOME_TO_SUPREME = '/welcome';

// MY_ACCOUNT
export const ACCOUNT_SETTINGS = `${MY_ACCOUNT_PREFIX}/account-settings`;
export const ACCOUNT_SUMMARY = `${MY_ACCOUNT_PREFIX}/account-summary`;
export const BOOKINGS = `${MY_ACCOUNT_PREFIX}/bookings/:status`;
export const BOOKINGS_ID = `${MY_ACCOUNT_PREFIX}/bookings/:status/:id`;
export const COMMUNICATION_PREFERENCES = `${MY_ACCOUNT_PREFIX}/communication-preferences`;
export const CONFIRM_DELETION = `${MY_ACCOUNT_PREFIX}/confirm-deletion/:token`;
export const DELETE_ACCOUNT = `${MY_ACCOUNT_PREFIX}/delete`;
export const EDIT_PAYMENT_METHOD = `${MY_ACCOUNT_PREFIX}/payment-methods/edit`;
export const FAVORITES = `${MY_ACCOUNT_PREFIX}/favorites`;
export const GOLFER_STATS = `${MY_ACCOUNT_PREFIX}/golfer-stats`;
export const GOLFER_STATS_EDIT = `${MY_ACCOUNT_PREFIX}/golfer-stats/edit`;
export const MESSAGE_PREFERENCES = `${MY_ACCOUNT_PREFIX}/message-preferences`;
export const MODERATOR_REVIEWS = `${MY_ACCOUNT_PREFIX}/manage-reviews/:status`;
export const MY_MEMBERSHIPS = `${MY_ACCOUNT_PREFIX}/my-memberships`;
export const MY_MEMBERSHIP = `${MY_ACCOUNT_PREFIX}/membership`;
export const MY_PROFILE = `${MY_ACCOUNT_PREFIX}/my-profile`;
export const MY_SG_CLUB = `${MY_ACCOUNT_PREFIX}/sg-club`;
export const NEW_PAYMENT_METHOD = `${MY_ACCOUNT_PREFIX}/payment-methods/new`;
export const PASSWORD_EDIT = `${MY_ACCOUNT_PREFIX}/edit-password`;
export const PAYMENT_METHODS = `${MY_ACCOUNT_PREFIX}/payment-methods`;
export const PERSONAL_INFO = `${MY_ACCOUNT_PREFIX}/personal-info`;
export const PRICE_ALERTS = `${MY_ACCOUNT_PREFIX}/price-alerts`;
export const REWARDS = `${MY_ACCOUNT_PREFIX}/rewards`;

// NETWORK MEMBERSHIP
export const GOLF_VANTAGE_PROMOTION = `${NETWORK_MEMBERSHIP}/golfvantage`;
export const NETWORK_MEMBERSHIP_JOIN = '/membership-join';
export const NETWORK_MEMBERSHIP_SALES = `${NETWORK_MEMBERSHIP}`;
export const NETWORK_MEMBERSHIP_COURSES = `${MY_MEMBERSHIP}/network-courses`;
export const NETWORK_PARTICIPATING_COURSES = `${NETWORK_MEMBERSHIP}/network-courses`;
export const NETWORK_OFFERS = `${MY_ACCOUNT_PREFIX}/network-offers/:status`;
export const NETWORK_OFFERS_REDEEM = `${MY_ACCOUNT_PREFIX}/network-offers/:status/:id`;
export const NETWORK_PARTICIPATING_PARTNERS = '/participating-partners';

// PARTNER OFFERS
export const PARTNER_OFFERS = `${MY_ACCOUNT_PREFIX}/membership/partner-offers`;

// BLOGS
export const TEE_TIME_RATES_BLOG = '/average-tee-time-rates-across-united-states';
export const SUPER_BOWL_BLOG = '/what-to-do-in-new-orleans-around-super-bowl-lix';

// OTHERS
export const WEB_VIEW_DONE = '/web-view-done';
export const ANONYMOUS_PURCHASE = '/anonymous-purchase';
export const PERFORM_APP_ACTION = '/perform-app-action';
