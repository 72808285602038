import { DIGITAL_WALLETS } from 'utils/constants';
import SupremeGolfApiClient from './SupremeGolfApiClient';

const GUEST_ENDPOINT = 'v6/guests';

class GuestApi {
  static async validateEmail({ email }) {
    const response = await SupremeGolfApiClient.get(`${GUEST_ENDPOINT}/validate-email`, { email });
    return response.data;
  }

  static async addGuestCreditCard({ token, gRecaptchaResponseData }) {
    const response = await SupremeGolfApiClient.post(`${GUEST_ENDPOINT}/credit_cards`, {
      token, gRecaptchaResponseData,
    });
    return response.data;
  }

  static async makeGuestPayment({ paymentType, paymentData, authToken }) {
    const payload = { ...paymentData, authToken };

    if (paymentType === 'googlePay' || paymentType === 'applePay') {
      payload.gatewayId = paymentData.gatewayId;
      payload.gatewayToken = paymentData.gatewayToken;
      payload.digitalWallet = DIGITAL_WALLETS[paymentType];
    } else {
      payload.creditCardAccessToken = paymentData.creditCardAccessToken;
    }
    const response = await SupremeGolfApiClient.post(`${GUEST_ENDPOINT}/reservations`, payload);
    return response.data;
  }
}

export default GuestApi;
