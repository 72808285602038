import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const DEFAULT_TTL = 4 * 60 * 1000; // default time to live (5 mins)

const { Types, Creators } = createActions({
  setCache: ['name', 'data', 'ttl'],
  getCache: ['name'],
  invalidateCache: ['name'],
  removeCache: ['name'],
  resetCache: [],
});

export const CacheTypes = Types;
export default Creators;

const INITIAL_STATE = Immutable.from({
  local: {},
});

const resetCache = () => INITIAL_STATE;

const setCache = (state, { name, data, ttl = DEFAULT_TTL }) => state.merge({
  local: {
    ...state.local,
    [name]: {
      data,
      timeStamp: Date.now(),
      ttl,
      stale: false,
    },
  },
});

const invalidateCache = (state, { name }) => {
  if (!state.local[name]) return state;
  const newState = { ...state.local[name], stale: true };

  return state.merge({ local: { ...state.local, [name]: newState } });
};

const removeCache = (state, { name }) => {
  const newCache = { ...state.local };
  delete newCache[name];
  return state.merge({ local: { ...newCache } });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_CACHE]: setCache,
  [Types.REMOVE_CACHE]: removeCache,
  [Types.RESET_CACHE]: resetCache,
  [Types.INVALIDATE_CACHE]: invalidateCache,
});
