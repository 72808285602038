// Keep this file in alphabetical order.
module.exports = {
  breadcrumbs: false,
  claimCourse: false,
  courseOperators: true,
  courseOperatorsFreeMarketplace: true,
  courseOperatorsFreeMarketplaceSignupDetails: true,
  courseOperatorsVipPerks: false,
  courseOperatorsGolfCourseResources: false,
  courseReviews: true,
  featuredContests: false,
  courseSuggestion: false,
  dealsNav: false,
  dealsPage: false,
  deleteUser: false,
  disclaimerPage: false,
  exploreCountriesStates: false,
  extendedSupportForm: false,
  feedbackPage: false,
  forCourses: true,
  giftCardLink: false,
  golfTimePromotions: false,
  golfSpecials: false,
  hasNavMenu: true,
  hereToHelp: false,
  marketingCommunication: false,
  marketingOptInDefault: false,
  membership: false,
  moderator: false,
  nearMeNav: true,
  networkMembership: false,
  showNetworkSales: false,
  offers: false,
  partnerLogo: false,
  poweredByLogo: true,
  poweredByLogoScenery: false,
  priceAlerts: false,
  providerLogos: false,
  recommendedCourses: true,
  reviewAttachmentCta: false,
  rewardsAccumulation: false,
  rewardsReferral: false,
  sectionCourses: false,
  shareReservation: false,
  showDealsOnly: true,
  showForeplayReviewedOnly: false,
  showNearByAlertForm: false,
  showPrepaidRoundsFilter: true,
  showBarstoolBestFilter: false,
  showNetworkMembershipFilter: false,
  storeUrl: false,
  subScenery: false,
  supportReason: true,
  topDeals: true,
  barstoolVip: false,
  showTeeTimesBlog: false,
  showSuperBowlBlog: false,
};
