import {
  call, put, takeLatest, all, select,
} from 'redux-saga/effects';
import SelectProviderActions, {
  SelectProviderTypes,
} from 'reducers/selectProvider';
import TeeTimeProviderApi from 'apis/supremeGolfApi/TimeSlotsApi';
import DateHelper from 'utils/dateHelper';
import SearchParamsActions from 'reducers/searchParams';
import flowActions from 'reducers/flow';
import { teeTimeFailure } from 'sagas/checkout';
import { BOOKING_LIMIT_ERROR_CODE, RATE_LIMIT_ERROR_CODE } from 'utils/constants';
import { NOT_FOR_SALE_MEMBERSHIP_ERROR_CODE, NOT_ACTIVE_MEMBERSHIP_ERROR_CODE } from 'utils/errorCodes';

function getValidTeeTimeProviders(providers, preparedTeeTimes) {
  const result = [];
  providers.forEach((provider, index) => {
    if (!('error' in preparedTeeTimes[index])) {
      result.push({ ...provider, ...preparedTeeTimes[index] });
    }
  });
  return result;
}

const validatePrepareError = (preparedTeeTimes) => {
  const teeTimeError = { status: '', error: null };

  const bookingLimitError = preparedTeeTimes.every((preparedTeeTime) => {
    if ('error' in preparedTeeTime && BOOKING_LIMIT_ERROR_CODE === preparedTeeTime.statusCode) {
      teeTimeError.status = preparedTeeTime.statusCode;
      teeTimeError.error = preparedTeeTime.error;
      return true;
    }
    return false;
  });

  const teeTimeNotAvailable = preparedTeeTimes.some((preparedTeeTime) => {
    const notAvailable = preparedTeeTime?.statusCode && preparedTeeTime?.error;
    const rateLimitReached = RATE_LIMIT_ERROR_CODE === preparedTeeTime.statusCode;
    if (notAvailable || rateLimitReached) {
      teeTimeError.status = preparedTeeTime.statusCode;
      teeTimeError.error = rateLimitReached ? preparedTeeTime.error : null;
    }

    return notAvailable || rateLimitReached;
  });

  return { bookingLimitError, teeTimeNotAvailable, teeTimeError };
};

export function* getTeeTimeProviders({ courseId, params }) {
  try {
    const {
      date,
      players: qty,
      selectedHoles: numHoles,
      selectedCart: isRiding,
      timeSlot,
      selectedRateType: rateType,
      isPrepaidOnly,
    } = params;

    const searchParams = {
      courseId,
      date: date && DateHelper.formatDate(date),
      qty,
      isRiding,
      numHoles,
      timeSlot,
      rateType,
    };

    if (rateType?.majorRateType !== 'lightning') searchParams.isPrepaidOnly = isPrepaidOnly;

    const teeTimeToken = yield select(({ flow }) => flow?.teeTimes?.authToken);
    const rateTypeToken = yield select(({ flow }) => flow?.rateTypes?.authToken);
    const { teeTimes: teeTimeProviders, authToken } = yield call(
      TeeTimeProviderApi.getTeeTimeProviders,
      searchParams,
      rateTypeToken || teeTimeToken,
    );
    yield put(flowActions.setfProvider({ authToken }));
    sessionStorage.setItem('providerToken', authToken);

    yield put(SelectProviderActions.setIsLoadingProviderList(false));

    if (teeTimeProviders.length === 1 && !teeTimeProviders[0].allowBookingRedirect) {
      yield put(SearchParamsActions.setSkipProvider(true));
      yield put(SearchParamsActions.performCheckoutPreparation(
        teeTimeProviders[0].id,
        teeTimeProviders[0].prepaymentRuleId,
        undefined,
        teeTimeProviders[0].provider,
        teeTimeProviders[0].sourceProvider,
      ));
    } else {
      const preparedTeeTimes = yield all(teeTimeProviders.map(
        (provider) => call(TeeTimeProviderApi.prepare, {
          prepaymentRuleId: provider.prepaymentRuleId,
          qty,
          teeTimeId: provider.id,
        }),
      ));
      const {
        bookingLimitError,
        teeTimeNotAvailable, teeTimeError,
      } = validatePrepareError(preparedTeeTimes);

      if (bookingLimitError || teeTimeNotAvailable) {
        const { error: errorMessage, status } = teeTimeError;
        yield* teeTimeFailure(status, { errorMessage });
      }

      const validProviders = getValidTeeTimeProviders(teeTimeProviders, preparedTeeTimes);

      yield put(SelectProviderActions.getProvidersDone(validProviders));
    }
  } catch (error) {
    const { response: { data: { code: errorCode, error: errorMessage } = {} } = {} } = error;

    if (errorMessage === 'Authorization Failed') {
      yield put(SelectProviderActions.getProvidersError(error.message));
      yield put(SearchParamsActions.performSearch());
      return;
    }

    if (errorCode === NOT_FOR_SALE_MEMBERSHIP_ERROR_CODE) {
      const { timeSlot } = params;
      yield* teeTimeFailure(error.response.status, { errorMessage, timeSlot });
    } else if (errorCode === NOT_ACTIVE_MEMBERSHIP_ERROR_CODE) {
      if (params.isRedirectedToMembership) yield put(SearchParamsActions.performRateTypeSearch());
      else yield put(SearchParamsActions.performMembershipSearch());
    } else {
      yield put(SelectProviderActions.getProvidersError(error.message));
    }
  }
}

function* getTeeTimeProvidersWatcher() {
  yield takeLatest(SelectProviderTypes.GET_PROVIDERS, getTeeTimeProviders);
}

export default [
  getTeeTimeProvidersWatcher,
];
