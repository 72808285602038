import {
  put, takeLatest, call, getContext, select, take, race, delay,
} from 'redux-saga/effects';
import pickBy from 'lodash/pickBy';
import { push, replace } from 'react-router-redux';
import qs from 'qs';

// Actions, please keep sorted alphabetically
import AlertActions from 'reducers/alert';
import BannersActions from 'reducers/banners';
import BestDealsActions from 'reducers/bestDeals';
import CheckoutActions from 'reducers/checkout';
import CityActions from 'reducers/city';
import ContestActions from 'reducers/contest';
import CourseDetailActions from 'reducers/courseDetail';
import CourseMembershipActions from 'reducers/courseMembership';
import CourseReviewsActions from 'reducers/courseReviews';
import CourseSuggestions from 'reducers/courseSuggestions';
import CreditCardsActions from 'reducers/paymentMethods';
import ExploreActions from 'reducers/explore';
import FaqsActions from 'reducers/faqs';
import FavoriteCitiesActions from 'reducers/favoriteCities';
import FavoriteCoursesActions from 'reducers/favoriteCourses';
import FlowActions from 'reducers/flow';
import InviteActions from 'reducers/invite';
import LocationActions from 'reducers/location';
import MarketingBannerActions from 'reducers/marketingBanner';
import MembershipActions from 'reducers/membership';
import NetworkMembershipActions from 'reducers/networkMembership';
import MembershipDetailsActions from 'reducers/membershipDetails';
import NavbarActions from 'reducers/navbar';
import PartnerOffersActions from 'reducers/partnerOffers';
import PriceAlertsActions from 'reducers/priceAlerts';
import ProfileActions from 'reducers/profile';
import RateTypesActions from 'reducers/rateTypes';
import RegistrationActions from 'reducers/registrations';
import ReservationsActions from 'reducers/reservations';
import RewardsActions from 'reducers/rewards';
import SearchCoursesActions from 'reducers/searchCourses';
import SearchFiltersActions from 'reducers/searchFilters';
import SearchParamsActions from 'reducers/searchParams';
import SelectProvidersAction from 'reducers/selectProvider';
import SessionActions from 'reducers/session';
import SiteActions from 'reducers/site';
import TimeSlotsActions from 'reducers/timeSlots';
import TrackingActions from 'reducers/tracking';
import UnlockActions from 'reducers/unlock';
import UserAgreementsActions from 'reducers/userAgreements';
import WelcomeActions from 'reducers/welcome';

// utils, please keep sorted alphabetically by file name
import { PLAYERS_RANGE, BANNED_ERROR_MESSAGE, CURRENT_LOCATION } from 'utils/constants';
import { features } from 'utils/features';
import DateHelper from 'utils/dateHelper';
import LocationHelper from 'utils/locationHelper';
import { onRoute, getQsAndParams } from 'utils/routeHelpers';
import {
  ABOUT,
  ACCOUNT_SETTINGS,
  ACCOUNT_SUMMARY,
  BOOKING_CONFIRMATION_AT,
  BOOKING_CONFIRMATION,
  BOOKING_OPTIONS,
  BOOKINGS_ID,
  BOOKINGS_INVITE,
  BOOKINGS,
  CHECKOUT,
  CITY_SEARCH_RESULT,
  COMMUNICATION_PREFERENCES,
  CONFIRM_DELETION,
  COURSE_DETAILS,
  COURSE_REVIEW,
  COURSE_REVIEWS,
  COURSE_SEARCH_RESULT,
  DEAL,
  DEALS,
  DELETE_ACCOUNT,
  EXPLORE_COUNTRY,
  EXPLORE_STATE,
  FAQS,
  FAVORITES,
  GOLFER_STATS,
  HOME,
  MESSAGE_PREFERENCES,
  MEMBERSHIP_UPSELL,
  MODERATOR_REVIEWS,
  MY_PROFILE,
  MY_SG_PREFIX,
  MY_ACCOUNT_PREFIX,
  MY_MEMBERSHIP,
  MY_SG_CLUB,
  NETWORK_MEMBERSHIP_BENEFITS,
  NETWORK_MEMBERSHIP_COURSES,
  NETWORK_MEMBERSHIP_JOIN,
  NETWORK_MEMBERSHIP_SALES,
  NETWORK_PARTICIPATING_COURSES,
  NEW_COURSE_REVIEW,
  NEW_PAYMENT_METHOD,
  NOT_FOUND,
  PAYMENT_METHODS,
  PRICE_ALERTS,
  PRIVACY_POLICY,
  RATE_TYPES,
  REQUEST_RESET_PASSWORD,
  RESET_PASSWORD,
  REWARDS,
  SECTION_COURSES,
  SELECT_PROVIDER,
  SG_CLUB,
  SIGN_IN,
  SIGN_UP,
  SUPPORT,
  TEE_TIME_ERROR,
  TEE_TIME_NOT_ALLOWED,
  TEE_TIME_NOT_AVAILABLE,
  TERMS_AND_CONDITIONS,
  UNLOCK,
  UNSUBSCRIBE,
  UNSUBSCRIBE_PRE_USERS,
  VERIFY_CUSTOMER_DETAILS,
  WELCOME_TO_SUPREME,
  UNLOCK_ACCOUNT_FAILED,
  WEB_VIEW_DONE,
  ANONYMOUS_PURCHASE,
  NETWORK_OFFERS,
  NETWORK_OFFERS_REDEEM,
  GOLF_VANTAGE_PROMOTION,
  PARTNER_OFFERS,
  NETWORK_PARTICIPATING_PARTNERS,
} from 'utils/routes';

import { isMobileScreen } from 'utils/screenHelper';
import { goToSignInHandler } from './session';
import { setRwgParams } from './app';

function parseRangeParam(type, value) {
  return value ? [type(value[0]), type(value[1])] : null;
}

function parseSimpleParam(type, value) {
  return value ? type(value) : null;
}

function parseArrayParam(type, value) {
  if (value) {
    return value.map((v) => type(v));
  }
  return PLAYERS_RANGE;
}

function parseSearchParams(queryParams) {
  const {
    cart,
    date,
    dealsOnly,
    holes,
    hotDealsSearch,
    foreplayReviewedOnly,
    id,
    location = {},
    locationRange,
    nearMeSearch,
    players,
    playersRange,
    previousRateTypes,
    price,
    rate,
    rateTypes,
    selectedCart,
    selectedHoles,
    selectedRateType,
    time,
    timeSlot,
    onlySearch,
    prepaymentRuleId,
    isPrepaidOnly,
    barstoolBestOnly,
    marketingPromotionOnly,
    networkMembershipOnly,
    isBestDeal,
    skipRate,
    skipProvider,
    isRedirectedToMembership,
    searchedCourse,
    isRecommendedDate,
  } = queryParams;

  location.id = parseSimpleParam(Number, location.id);
  location.distance = parseSimpleParam(Number, location.distance);
  const latitudeValue = parseSimpleParam(Number, location.latitude);
  const longitudeValue = parseSimpleParam(Number, location.longitude);
  const locationRangeValue = parseRangeParam(Number, locationRange);
  const dateValue = DateHelper.formatDateWithoutTime(date);
  const playersValue = parseSimpleParam(Number, players);
  const prepaymentRuleIdValue = parseSimpleParam(Number, prepaymentRuleId);
  const holesValue = parseSimpleParam(Number, holes);
  const selectedHolesValue = parseSimpleParam(Number, selectedHoles);
  const priceValue = parseRangeParam(Number, price);
  const timeValue = parseRangeParam(Number, time);
  const hotDealsSearchValue = hotDealsSearch === 'true';
  const foreplayReviewedOnlyValue = foreplayReviewedOnly === 'true';
  const nearMeSearchValue = nearMeSearch === 'true';
  const playersRangeValue = parseArrayParam(Number, playersRange);
  const rateTypesValue = rateTypes || [];
  const previousRateTypesValue = previousRateTypes || [];
  let cartValue = null;
  if (cart === 'true') {
    cartValue = true;
  } else if (cart === 'false') {
    cartValue = false;
  }

  let selectedCartValue = null;
  if (selectedCart === 'true') {
    selectedCartValue = true;
  } else if (selectedCart === 'false') {
    selectedCartValue = false;
  }

  let dealsOnlyValue = false;
  if (dealsOnly === 'true') {
    dealsOnlyValue = true;
  }

  let onlySearchValue = null;
  if (onlySearch === 'true') {
    onlySearchValue = true;
  } else if (onlySearch === 'false') {
    onlySearchValue = false;
  }

  const isPrepaidOnlyValue = isPrepaidOnly === 'true';
  const barstoolBestOnlyValue = barstoolBestOnly === 'true';
  const marketingPromotionOnlyValue = marketingPromotionOnly === 'true';
  const networkMembershipOnlyValue = networkMembershipOnly === 'true';
  const isBestDealValue = isBestDeal === 'true';
  const skipRateValue = skipRate === 'true';
  const skipProviderValue = skipProvider === 'true';

  let isRedirectedToMembershipValue = null;
  if (isRedirectedToMembership === 'true') {
    isRedirectedToMembershipValue = true;
  } else if (isRedirectedToMembership === 'false') {
    isRedirectedToMembershipValue = false;
  }

  let isRecommendedDateValue = null;
  if (isRecommendedDate === 'true') {
    isRecommendedDateValue = true;
  } else if (isRecommendedDate === 'false') {
    isRecommendedDateValue = false;
  }

  return {
    cart: cartValue,
    date: dateValue,
    dealsOnly: dealsOnlyValue,
    holes: holesValue,
    hotDealsSearch: hotDealsSearchValue,
    foreplayReviewedOnly: foreplayReviewedOnlyValue,
    id,
    location: {
      ...location,
      latitude: latitudeValue,
      longitude: longitudeValue,
    },
    locationRange: locationRangeValue,
    nearMeSearch: nearMeSearchValue,
    onlySearch: onlySearchValue,
    players: playersValue,
    playersRange: playersRangeValue,
    previousRateTypes: previousRateTypesValue,
    price: priceValue,
    rate,
    rateTypes: rateTypesValue,
    selectedCart: selectedCartValue,
    selectedHoles: selectedHolesValue,
    searchedCourse,
    selectedRateType,
    time: timeValue,
    timeSlot,
    prepaymentRuleId: prepaymentRuleIdValue,
    isPrepaidOnly: isPrepaidOnlyValue,
    barstoolBestOnly: barstoolBestOnlyValue,
    marketingPromotionOnly: marketingPromotionOnlyValue,
    networkMembershipOnly: networkMembershipOnlyValue,
    isBestDeal: isBestDealValue,
    skipRate: skipRateValue,
    skipProvider: skipProviderValue,
    isRedirectedToMembership: isRedirectedToMembershipValue,
    isRecommendedDate: isRecommendedDateValue,
  };
}

function unifyCoreLocationInfo(locationData, newLocationData, type) {
  const {
    fullName,
    name,
    hierarchizedUrl,
    id,
    latitude,
    longitude,
    slug,
    symbol,
  } = newLocationData;

  const newLocation = {
    hierarchizedUrl,
    id,
    label: fullName,
    latitude,
    longitude,
    name,
    slug,
    symbol,
    type,
  };

  return Object.assign(newLocation, pickBy(locationData));
}

function* unlock(action) {
  const { queryParams } = getQsAndParams(action.payload.location, UNLOCK);
  const unlockToken = queryParams.unlock_token;

  if (unlockToken) {
    yield put(UnlockActions.unlock(unlockToken));
  }
}

function* setSearchParamsValuesWithTeeTime(queryParams) {
  const {
    cart, date, dealsOnly, holes, id, location, locationRange, players, previousRateTypes,
    price, rate, rateTypes, selectedCart, selectedHoles, selectedRateType, time, timeSlot,
    networkMembershipOnly,
  } = parseSearchParams(queryParams);

  yield put(SearchParamsActions.setValues({
    ...pickBy({ id, location: { ...location } }),
    date: DateHelper.getDateFromPathWithDefault(date, DateHelper.DEFAULT_FORMAT).toDate(),
    players,
    holes,
  }, {
    cart,
    dateFilter: DateHelper.getDateFromPathWithDefault(date, DateHelper.DEFAULT_FORMAT).toDate(),
    dealsOnly,
    holesFilter: holes,
    locationFilter: location,
    locationRange,
    playersFilter: players,
    previousRateTypes,
    price,
    rate,
    rateTypes,
    selectedCart,
    selectedHoles,
    selectedRateType,
    time,
    timeSlot,
    networkMembershipOnly,
  }));
}

function* setSearchParamsValuesWithCourse(queryParams, params, courseVisited = false) {
  const {
    cart,
    date,
    dealsOnly,
    holes,
    id,
    isPrepaidOnly,
    location,
    locationRange,
    players,
    playersRange,
    previousRateTypes,
    price,
    rate,
    rateTypes,
    selectedCart,
    selectedHoles,
    selectedRateType,
    time,
    timeSlot,
    isBestDeal,
    skipRate,
    skipProvider,
    isRecommendedDate,
    networkMembershipOnly,
  } = parseSearchParams(queryParams);

  const { courseId } = params;
  let iterableTracking = null;
  if (courseVisited) {
    const iterable = yield getContext('iterable');
    iterableTracking = yield call(iterable.getTrackingParams, true);
  }

  yield put(CourseDetailActions.getCourseDetail(courseId, iterableTracking));
  yield race([
    take(CourseDetailActions.getCourseDetailDone().type),
    take(CourseDetailActions.getCourseDetailError().type),
  ]);

  const { course } = yield select(({ courseDetail }) => courseDetail);

  if (!course.id) {
    yield put(replace('/not-found'));
  }

  const unifiedLocation = unifyCoreLocationInfo(location, course, 'Course');

  const dateValue = DateHelper.getDateFromPathWithDefault(date, DateHelper.DEFAULT_FORMAT).toDate();

  yield put(SearchParamsActions.setValues({
    ...pickBy({ id, location: { slug: courseId, ...unifiedLocation } }),
    date: dateValue,
    players,
    holes,
  }, {
    cart,
    dateFilter: dateValue,
    dateSearch: dateValue,
    dealsOnly,
    holesFilter: holes,
    isPrepaidOnly,
    locationFilter: {
      slug: courseId,
      zipCode: course.addressZipcode,
      ...unifiedLocation,
    },
    locationRange,
    playersFilter: players,
    playersRange,
    previousRateTypes,
    price,
    rate,
    rateTypes,
    selectedCart,
    selectedHoles,
    selectedRateType,
    time,
    timeSlot,
    isBestDeal,
    skipRate,
    skipProvider,
    isRecommendedDate,
    networkMembershipOnly,
  }));
}

function* getRelevantTeeTimes(selectedDate, teeTimeId) {
  const date = DateHelper
    .getDateFromPathWithDefault(selectedDate)
    .format(DateHelper.DEFAULT_FORMAT);

  const { course } = yield select(({ courseDetail }) => courseDetail);
  yield put(TimeSlotsActions.getRelevantTeeTimes(course.id, date, teeTimeId));
}

function* searchTimeSlots(action) {
  const { queryParams, params } = getQsAndParams(action.payload.location, COURSE_SEARCH_RESULT);

  const { courseId } = params;

  const { teeTimeId, date: selectedDate, isAnchoredTeeTime } = queryParams || {};

  yield setSearchParamsValuesWithCourse(queryParams, params, true);
  yield* setRwgParams();

  if (isAnchoredTeeTime === 'true') {
    return yield getRelevantTeeTimes(selectedDate, teeTimeId);
  }

  yield put(BannersActions.setBanners(courseId));
  yield put(NetworkMembershipActions.resetNetworkMembershipBenefits());

  const {
    cart,
    date,
    holes,
    locationRange,
    players,
    price,
    rate,
    rateTypes,
    isPrepaidOnly,
    time,
    isRecommendedDate,
    networkMembershipOnly,
  } = parseSearchParams(queryParams);

  const { course } = yield select(({ courseDetail }) => courseDetail);

  yield put(CourseMembershipActions.setMembershipUpsellBackToUrl(
    `${action.payload.location.pathname}${action.payload.location.search}`,
  ));

  const dateValue = DateHelper.getDateFromPathWithDefault(date, DateHelper.DEFAULT_FORMAT).toDate();

  yield put(BestDealsActions.getBestDeals(course.id, {
    date: dateValue,
  }));

  yield put(TimeSlotsActions.getTimeSlots(course.id, {
    date: dateValue,
    players,
    holes,
    price,
    time,
    locationRange,
    cart,
    rate,
    rateTypes,
    isPrepaidOnly,
    isRecommendedDate,
    slug: course.slug,
    networkMembershipOnly,
  }));
}

function* searchRateTypes(action) {
  const { restoreSessionFinished } = yield select((state) => state.session);
  if (!restoreSessionFinished) {
    yield take((innerAction) => (
      innerAction.type === SessionActions.restoreSessionDone().type
      || innerAction.type === SessionActions.restoreSessionError().type
    ));
  }
  const location = yield select((state) => state.router.location);
  const goToSignIn = yield goToSignInHandler({
    path: `${location.pathname}${location.search}`,
    newWindow: false,
    trackEvent: null,
  });
  if (goToSignIn) return;

  const { queryParams, params } = getQsAndParams(action.payload.location, RATE_TYPES);

  const { courseId } = params;

  yield put(BannersActions.setBanners(courseId));

  yield setSearchParamsValuesWithCourse(queryParams, params);

  if (action.payload.isFirstRendering) {
    if (sessionStorage.getItem('rateTypesURL') !== `${action.payload.location.pathname}${action.payload.location.search}`) {
      yield put(SearchParamsActions.performSearch());
      return;
    }
  }

  const {
    cart,
    date,
    holes,
    players,
    price,
    rate,
    rateTypes,
    time,
    timeSlot,
    isPrepaidOnly,
  } = parseSearchParams(queryParams);

  const dateValue = DateHelper.getDateFromPathWithDefault(date, DateHelper.DEFAULT_FORMAT).toDate();

  yield put(CourseMembershipActions.setMembershipUpsellBackToUrl(
    `${action.payload.location.pathname}${action.payload.location.search}`,
  ));

  yield put(RateTypesActions.getRateTypes(courseId, {
    date: dateValue,
    players,
    holes,
    price,
    time,
    cart,
    rate,
    timeSlot,
    rateTypes,
    isPrepaidOnly,
  }));
}

function* searchMembership(action) {
  const { queryParams, params } = getQsAndParams(action.payload.location, MEMBERSHIP_UPSELL);

  const { courseId } = params;
  const { backToUrl, redirectToUrl, isRedirectingToMembership } = queryParams;

  yield put(CourseMembershipActions.setMembershipUpsellBackToUrl(backToUrl || ''));
  yield put(CourseMembershipActions.setMembershipUpsellRedirectToUrl(redirectToUrl || ''));

  if (isRedirectingToMembership) return;

  yield put(CourseMembershipActions.getCourseMembership(courseId));
}

function* searchSimilarCourses(action) {
  const { queryParams } = getQsAndParams(action.payload.location, CITY_SEARCH_RESULT);
  const {
    cart,
    date,
    dealsOnly,
    holes,
    foreplayReviewedOnly,
    barstoolBestOnly,
    marketingPromotionOnly,
    networkMembershipOnly,
    location,
    locationRange,
    players,
    previousRateTypes,
    price,
    rate,
    rateTypes,
    searchedCourse,
    time,
  } = parseSearchParams(queryParams);

  const dateValue = DateHelper.getDateFromPathWithDefault(date, DateHelper.DEFAULT_FORMAT).toDate();

  yield put(SearchCoursesActions.getSimilarCourses(searchedCourse, {
    date: dateValue,
    players,
    holes,
    foreplayReviewedOnly,
    barstoolBestOnly,
    marketingPromotionOnly,
    networkMembershipOnly,
    price,
    time,
    cart,
    rate,
    locationRange,
    rateTypes,
  }));
  yield race([
    take(SearchCoursesActions.getSimilarCoursesDone().type),
    take(SearchCoursesActions.getSimilarCoursesError().type),
  ]);

  const { course } = yield select(({ search }) => search.searchCourses);

  if (!course.id) { yield put(replace('/not-found')); }

  const unifiedLocation = unifyCoreLocationInfo(location, course, 'Course');
  const hierarchizedUrl = unifiedLocation.hierarchizedUrl.replace(/\/(?=[^/]*$)/, '?searchedCourse=');

  yield put(SearchParamsActions.setValues({
    ...pickBy({
      id: searchedCourse,
      location: { slug: searchedCourse, ...unifiedLocation, hierarchizedUrl },
    }),
    date: dateValue,
    players,
    holes,
  }, {
    cart,
    dateFilter: dateValue,
    dealsOnly,
    holesFilter: holes,
    locationFilter: {
      slug: searchedCourse,
      zipCode: course.addressZipcode,
      ...unifiedLocation,
      hierarchizedUrl,
    },
    locationRange,
    playersFilter: players,
    previousRateTypes,
    price,
    rate,
    rateTypes,
    time,
  }));
}

function* searchCourses(action) {
  const { queryParams, params } = getQsAndParams(action.payload.location, CITY_SEARCH_RESULT);
  const {
    cart,
    date,
    dealsOnly,
    holes,
    foreplayReviewedOnly,
    barstoolBestOnly,
    marketingPromotionOnly,
    networkMembershipOnly,
    location,
    locationRange,
    players,
    previousRateTypes,
    price,
    rate,
    rateTypes,
    searchedCourse,
    time,
  } = parseSearchParams(queryParams);

  const { sortBy } = yield select((state) => state.search.searchFilters);

  if (!sortBy) {
    const sortByValue = searchedCourse ? 'best-match' : 'distance';
    yield put(SearchFiltersActions.setSortByCourses(sortByValue));
  }

  yield put(SearchParamsActions.setPreviousUrl(`${action.payload.location.pathname}${action.payload.location.search}`));
  const hierarchizedUrl = `/${params.country}/${params.state}/${params.city}`;
  const cityMeta = {};
  if (hierarchizedUrl.includes('unknown') && queryParams?.cityId) cityMeta.id = queryParams.cityId;
  else cityMeta.hierarchizedUrl = hierarchizedUrl;
  yield put(CityActions.getCity({ rateTypes, ...cityMeta }));

  yield race([
    take(CityActions.getCityDone().type),
    take(CityActions.getCityError().type),
  ]);

  const cityData = yield select(({ city }) => city.city);

  if (!cityData.id && !hierarchizedUrl.includes('unknown')) {
    yield put(replace('/not-found'));
  }

  if (searchedCourse) {
    yield searchSimilarCourses(action);
    return;
  }

  const unifiedLocation = unifyCoreLocationInfo(location, cityData, 'City');

  const dateValue = DateHelper.getDateFromPathWithDefault(date, DateHelper.DEFAULT_FORMAT).toDate();

  yield put(SearchParamsActions.setValues({
    ...pickBy({ location: { ...unifiedLocation } }),
    date: dateValue,
    players,
    holes,
  }, {
    cart,
    dateFilter: dateValue,
    dealsOnly,
    isBarstoolBestOnly: barstoolBestOnly,
    isBarstoolVipOnly: marketingPromotionOnly,
    networkMembershipOnly,
    holesFilter: holes,
    locationFilter: {
      ...unifiedLocation,
    },
    locationRange,
    playersFilter: players,
    previousRateTypes,
    price,
    rate,
    rateTypes,
    time,
    searchedCourse,
  }));

  yield put(BannersActions.setBanners());

  yield put(SearchCoursesActions.getCourses(
    location.latitude,
    location.longitude,
    hierarchizedUrl,
    {
      date: dateValue,
      players,
      holes,
      foreplayReviewedOnly,
      barstoolBestOnly,
      marketingPromotionOnly,
      networkMembershipOnly,
      price,
      time,
      cart,
      rate,
      locationRange,
      rateTypes,
    },
  ));

  const iterable = yield getContext('iterable');
  const iterableTracking = yield call(iterable.getTrackingParams);
  const { firstName, lastName, id: userId } = yield select(({ profile }) => profile);

  yield put(TrackingActions.trackEvent('cityVisited', {
    campaignId: iterableTracking?.campaign_id,
    templateId: iterableTracking?.template_id,
    messageId: iterableTracking?.message_id,
    userId,
    firstName,
    lastName,
    cityURL: cityData?.cityUrl,
    dateOfPlay: date,
    city: {
      slug: cityData.slug,
      name: cityData.fullName,
    },
    state: cityData?.state?.name,
    platform: 'web',
    players,
    holes,
    cart,
    showDealsOnly: dealsOnly,
  }));
}

function* currentLocation() {
  const { isDone } = yield select((state) => state.location);
  if (!isDone) {
    yield take((innerAction) => (
      innerAction.type === LocationActions.getLocationDone().type
      || innerAction.type === LocationActions.getLocationError().type
    ));
  }
  const { location, router } = yield select((state) => state);
  if (location?.hierarchizedUrl) {
    yield put(push(`/explore${location?.hierarchizedUrl}${router?.location?.search}`));
  } else {
    yield put(push('/'));
  }
}

function* searchSectionCourses(action) {
  const {
    queryParams,
    params: { courseSectionSlug },
  } = getQsAndParams(action.payload.location, SECTION_COURSES);
  const {
    cart,
    date,
    holes,
    locationRange,
    players,
    previousRateTypes,
    price,
    rate,
    rateTypes,
    time,
  } = parseSearchParams(queryParams);

  const dateValue = DateHelper.getDateFromPathWithDefault(date, DateHelper.DEFAULT_FORMAT).toDate();

  yield put(SearchParamsActions.setValues({
    date: dateValue,
    players,
    holes,
  }, {
    cart,
    dateFilter: dateValue,
    holesFilter: holes,
    locationRange,
    playersFilter: players,
    previousRateTypes,
    price,
    rate,
    rateTypes,
    time,
  }));

  let lat;
  let lon;
  try {
    const location = yield call(LocationHelper.getUserLocation);
    lat = location.coords.latitude;
    lon = location.coords.longitude;
  } catch (error) {
    yield put(SearchFiltersActions.setSortByCourses('name-asc'));
    yield put(LocationActions.getLocationError(error.message));
  }

  yield put(SearchCoursesActions.getSectionCourses(
    lat,
    lon,
    {
      courseSectionSlug,
      date: dateValue,
      players,
      holes,
      price,
      time,
      cart,
      rate,
      locationRange,
      rateTypes,
    },
  ));
}

function* getProfile() {
  yield put(ProfileActions.getProfile());
}

function* bookingsInvite(action) {
  const { params } = getQsAndParams(action.payload.location, BOOKINGS_INVITE);
  const { inviteId } = params;

  const { restoreSessionFinished } = yield select((state) => state.session);
  if (!restoreSessionFinished) {
    yield take((innerAction) => (
      innerAction.type === SessionActions.restoreSessionDone().type
      || innerAction.type === SessionActions.restoreSessionError().type
    ));
  }

  yield put(InviteActions.getInviteDetails(inviteId));
  yield race([
    take(InviteActions.getInviteDetailsDone().type),
    take(InviteActions.getInviteDetailsError().type),
  ]);

  const invitation = yield select(({ invite }) => invite?.invitation);
  const { loggedIn, email, id } = yield select(({ profile }) => profile);

  if (invitation?.inviterEmail === email) {
    yield put(push(`${MY_ACCOUNT_PREFIX}/bookings/upcoming/${invitation?.reservationId}`));
    yield put(InviteActions.setIsLoadingPageDone(true));
    return;
  }

  if (invitation?.invitationStatus === 'accepted') {
    if (invitation?.userId === id) yield put(push(`${MY_ACCOUNT_PREFIX}/bookings/upcoming/${invitation?.reservationId}`));
    yield put(InviteActions.setIsLoadingPageDone(true));
    return;
  }

  if (invitation?.invitationStatus === 'pending') {
    yield put(InviteActions.setIsLoadingPageDone(true));
    return;
  }

  if (!loggedIn) {
    yield put(InviteActions.setIsLoadingPageDone(true));
    return;
  }

  const location = yield select((state) => state.router.location);

  yield put(InviteActions.setIsLoadingPageDone(true));
  yield goToSignInHandler({
    path: `${location.pathname}`,
    newWindow: false,
    trackEvent: null,
  });
}

function* loadUserReservations(action) {
  const { params } = getQsAndParams(action.payload.location, BOOKINGS);
  const { status } = params;

  yield put(ReservationsActions.getReservations(status));
}

function* loadUnsubscribe(action) {
  const { queryParams, params } = getQsAndParams(action.payload.location, UNSUBSCRIBE);
  const { mailingList } = params;
  yield put(UserAgreementsActions.setUnsubscribePage(mailingList));

  const { restoreSessionFinished } = yield select((state) => state.session);
  if (!restoreSessionFinished) {
    yield take((innerAction) => (
      innerAction.type === SessionActions.restoreSessionDone().type
      || innerAction.type === SessionActions.restoreSessionError().type
    ));
  }

  if (!queryParams?.token || !queryParams?.email) {
    const location = yield select((state) => state.router.location);
    yield goToSignInHandler({
      path: `${location.pathname}`,
      newWindow: false,
      trackEvent: null,
    });
  } else {
    const profile = yield select((state) => state.profile);
    if (profile.loggedIn) yield put(SessionActions.signOut(false));
  }
}

function* loadUnsubscribePreUser(action) {
  const { queryParams, params } = getQsAndParams(action.payload.location, UNSUBSCRIBE_PRE_USERS);
  const { mailingList } = params;
  yield put(UserAgreementsActions.setUnsubscribePage(mailingList));

  const { restoreSessionFinished } = yield select((state) => state.session);
  if (!restoreSessionFinished) {
    yield take((innerAction) => (
      innerAction.type === SessionActions.restoreSessionDone().type
      || innerAction.type === SessionActions.restoreSessionError().type
    ));
  }

  if (!queryParams?.token || !queryParams?.email) {
    yield put(push(`/unsubscribe/${mailingList}`));
  } else {
    const profile = yield select((state) => state.profile);
    if (profile.loggedIn) yield put(SessionActions.signOut(false));
  }
}

function* loadModerateContent(action) {
  const { params } = getQsAndParams(action.payload.location, MODERATOR_REVIEWS);
  const { status } = params;
  const { isModerator } = yield select(({ profile }) => profile);

  if (!isModerator) {
    yield put(CourseReviewsActions.redirectIfNotModerator());
  }

  yield put(CourseReviewsActions.getModeratorReviews(status));
}

function* searchProviders(action) {
  yield put(CheckoutActions.resetCheckout());
  const { queryParams, params } = getQsAndParams(action.payload.location, SELECT_PROVIDER);

  const { restoreSessionFinished } = yield select((state) => state.session);
  if (!restoreSessionFinished) {
    yield take((innerAction) => (
      innerAction.type === SessionActions.restoreSessionDone().type
      || innerAction.type === SessionActions.restoreSessionError().type
    ));
  }

  const location = yield select((state) => state.router.location);
  const goToSignIn = yield goToSignInHandler({
    path: `${location.pathname}${location.search}`,
    newWindow: false,
    trackEvent: null,
  });
  if (goToSignIn) return;

  yield put(SelectProvidersAction.setIsLoadingProviderList(true));
  yield setSearchParamsValuesWithCourse(queryParams, params);

  if (action.payload.isFirstRendering) {
    if (sessionStorage.getItem('providerURL') !== `${action.payload.location.pathname}${action.payload.location.search}`) {
      yield put(SearchParamsActions.performSearch());
      return;
    }
  }

  const {
    date,
    players,
    price,
    selectedCart,
    selectedHoles,
    selectedRateType,
    time,
    timeSlot,
    isPrepaidOnly,
    isRedirectedToMembership,
  } = parseSearchParams(queryParams);

  const { courseId } = params;

  const dateValue = DateHelper.getDateFromPathWithDefault(date, DateHelper.DEFAULT_FORMAT).toDate();

  yield put(BannersActions.setBanners());

  yield put(SelectProvidersAction.getProviders(courseId, {
    date: dateValue,
    players,
    selectedHoles,
    price,
    time,
    selectedCart,
    timeSlot,
    selectedRateType,
    isPrepaidOnly,
    isRedirectedToMembership,
  }));
}

function* checkout(action) {
  const { queryParams, params } = getQsAndParams(action.payload.location, CHECKOUT);

  if (queryParams?.isAnchoredTeeTime) yield put(SessionActions.restoreSession());
  const { restoreSessionFinished } = yield select((state) => state.session);
  if (!restoreSessionFinished) {
    yield take((innerAction) => (
      innerAction.type === SessionActions.restoreSessionDone().type
      || innerAction.type === SessionActions.restoreSessionError().type
    ));
  }

  const { loggedIn } = yield select((state) => state.profile);
  const isGuest = queryParams?.isAnchoredTeeTime && !loggedIn;
  const location = yield select((state) => state.router.location);

  if (!isGuest) {
    const goToSignIn = yield goToSignInHandler({
      path: `${location.pathname}${location.search}`,
      newWindow: false,
      trackEvent: null,
    });
    if (goToSignIn) return;
  } else {
    yield put(SessionActions.redirectTo({ path: `${location.pathname}${location.search}`, * action() { yield put(CheckoutActions.resetCheckout()); } }));
  }

  yield setSearchParamsValuesWithCourse(queryParams, params);

  const shouldRedirect = sessionStorage.getItem('checkoutURL') !== `${action.payload.location.pathname}${action.payload.location.search}`;
  if (action.payload.isFirstRendering && shouldRedirect) {
    yield put(SearchParamsActions.performSearch());
    return;
  }

  const {
    id,
    players,
    prepaymentRuleId,
    isBestDeal,
    isRecommendedDate,
  } = parseSearchParams(queryParams);

  let internalOrigin;
  if (isBestDeal) {
    internalOrigin = 'hot_deal_tile';
  } else if (isRecommendedDate) {
    internalOrigin = 'Date Origin';
  }

  yield put(BannersActions.setBanners());

  const updatePrepareRecent = yield select((state) => state.checkout.updatePrepareRecent);
  if (updatePrepareRecent) {
    yield put(CheckoutActions.setUpdatePrepareRecent(false));
  } else {
    yield put(CheckoutActions.getPrepare({
      id, players, prepaymentRuleId, internalOrigin,
    }));
  }

  if (!isGuest) yield put(CreditCardsActions.getCreditCards());
}

function* bookingConfirmation(action) {
  const { queryParams, params } = getQsAndParams(
    action.payload.location,
    BOOKING_CONFIRMATION,
  );
  const { reservationId } = params;
  const { accessToken } = queryParams;
  yield put(ReservationsActions.getReservation(reservationId, 'confirmed', accessToken ?? ''));

  yield put(MarketingBannerActions.getMarketingBanner('booking-confirmation'));
}

function* bookingConfirmationAtCourse(action) {
  const { params } = getQsAndParams(action.payload.location, BOOKING_CONFIRMATION_AT);
  const { courseId } = params;

  yield put(CourseDetailActions.getCourseDetail(courseId));
}

function* teeTimeError(action) {
  yield put(CheckoutActions.resetCheckout());
  const { queryParams } = getQsAndParams(action.payload.location, TEE_TIME_ERROR);

  yield setSearchParamsValuesWithTeeTime(queryParams);
}

function* teeTimeNotAllowed(action) {
  const { queryParams } = getQsAndParams(action.payload.location, TEE_TIME_NOT_ALLOWED);

  yield setSearchParamsValuesWithTeeTime(queryParams);
}

function* teeTimeNotAvailable(action) {
  yield put(CheckoutActions.resetCheckout());
  const { queryParams, params } = getQsAndParams(action.payload.location, CHECKOUT);

  const { courseId } = params;

  yield setSearchParamsValuesWithTeeTime(queryParams);
  if (courseId || queryParams?.courseId) {
    yield put(CourseDetailActions.getCourseDetail(courseId || queryParams?.courseId));
  }
}

function* loadUserReservation(action) {
  const { params } = getQsAndParams(action.payload.location, BOOKINGS_ID);
  const { id, status } = params;

  yield put(ReservationsActions.getReservation(id, status));
}

function* trackPageView(action) {
  const { id: userId } = yield select(({ profile }) => profile);
  const gtm = yield getContext('gtm');

  yield call(gtm.trackPageView, action.payload.location.pathname, userId);
}

function* courseReview(action) {
  const { queryParams, params } = getQsAndParams(action.payload.location, COURSE_REVIEW);
  // eslint-disable-next-line camelcase
  const { tag_id } = queryParams;

  const {
    cart,
    date,
    dealsOnly,
    holes,
    location,
    locationRange,
    players,
    previousRateTypes,
    price,
    rate,
    rateTypes,
    time,
  } = parseSearchParams(queryParams);

  const { courseId: paramSlug, reviewId } = params;

  const dateValue = DateHelper.getDateFromPathWithDefault(date, DateHelper.DEFAULT_FORMAT).toDate();

  yield put(CourseDetailActions.getCourseDetail(paramSlug));

  yield race([
    take(CourseDetailActions.getCourseDetailDone().type),
    take(CourseDetailActions.getCourseDetailError().type),
  ]);

  const { course } = yield select(({ courseDetail }) => courseDetail);

  if (course.fullName) {
    location.label = course.fullName;
    location.hierarchizedUrl = course.hierarchizedUrl;
    location.type = 'Course';
  }

  yield put(SearchParamsActions.setValues({
    ...pickBy({ location: { slug: paramSlug, ...location } }),
    date: dateValue,
    players,
    holes,
  }, {
    cart,
    dateFilter: dateValue,
    dealsOnly,
    holesFilter: holes,
    locationFilter: {
      ...location,
      slug: paramSlug,
    },
    locationRange,
    playersFilter: players,
    previousRateTypes,
    price,
    rate,
    rateTypes,
    time,
  }));

  yield put(CourseReviewsActions.getCourseReviews(course.id, 'most-recent', tag_id, reviewId));
}

function* courseDetails(action) {
  const { queryParams, params } = getQsAndParams(action.payload.location, COURSE_DETAILS);

  // eslint-disable-next-line camelcase
  const { tab, tag_id } = queryParams || {};
  const courseTabs = ['details', 'reviews', 'tee-details'];

  if (!courseTabs.includes(tab)) {
    queryParams.tab = 'details';
    const path = qs.stringify(queryParams, { addQueryPrefix: true });
    yield put(push(`${action.payload.location.pathname}${path}`));
    return;
  }

  yield* setSearchParamsValuesWithCourse(queryParams, params);
  const { course } = yield select(({ courseDetail }) => courseDetail);
  if (tab === 'reviews') {
    yield put(CourseReviewsActions.getCourseReviews(course.id, 'most-recent', tag_id));
  } else if (tab === 'tee-details') {
    yield put(CourseDetailActions.getCourseScorecard(course.id));
  }
}

function* myMembership() {
  yield put(NetworkMembershipActions.resetChangeCreditCard());

  const { isFetchingStatusDone } = yield select((state) => state.site);
  if (!isFetchingStatusDone) {
    yield take((innerAction) => (
      innerAction.type === SiteActions.fetchSiteStatusDone().type
      || innerAction.type === SiteActions.fetchSiteStatusError().type
    ));
  }
  const { networkMembershipEnabled } = yield select((state) => state.site?.data);
  if (!networkMembershipEnabled) {
    yield put(replace(NOT_FOUND));
    return;
  }

  const { restoreSessionFinished } = yield select((state) => state.session);
  if (!restoreSessionFinished) {
    yield take((innerAction) => (
      innerAction.type === SessionActions.restoreSessionDone().type
      || innerAction.type === SessionActions.restoreSessionError().type
    ));
  }

  const user = yield select(({ profile }) => profile);

  if (user.loggedIn) {
    yield put(NetworkMembershipActions.getNetworkMembershipSubscription());
    yield put(CreditCardsActions.getCreditCards());
  }
}

function* networkOffers(action) {
  const { params } = getQsAndParams(action.payload.location, NETWORK_OFFERS);

  const { isFetchingStatusDone } = yield select((state) => state.site);
  if (!isFetchingStatusDone) {
    yield take((innerAction) => (
      innerAction.type === SiteActions.fetchSiteStatusDone().type
      || innerAction.type === SiteActions.fetchSiteStatusError().type
    ));
  }
  const { networkMembershipEnabled } = yield select((state) => state.site?.data);
  if (!networkMembershipEnabled) {
    yield put(replace(NOT_FOUND));
    return;
  }

  const { restoreSessionFinished } = yield select((state) => state.session);
  if (!restoreSessionFinished) {
    yield take((innerAction) => (
      innerAction.type === SessionActions.restoreSessionDone().type
        || innerAction.type === SessionActions.restoreSessionError().type
    ));
  }
  const profile = yield select((state) => state.profile);
  if (profile.loggedIn) {
    yield put(NetworkMembershipActions.getCoursesWithOffers(params?.status));
  }
}

function* networkOffersRedeem(action) {
  const { params } = getQsAndParams(action.payload.location, NETWORK_OFFERS_REDEEM);

  const { isFetchingStatusDone } = yield select((state) => state.site);
  if (!isFetchingStatusDone) {
    yield take((innerAction) => (
      innerAction.type === SiteActions.fetchSiteStatusDone().type
      || innerAction.type === SiteActions.fetchSiteStatusError().type
    ));
  }
  const { networkMembershipEnabled } = yield select((state) => state.site?.data);
  if (!networkMembershipEnabled) {
    yield put(replace(NOT_FOUND));
    return;
  }

  const { restoreSessionFinished } = yield select((state) => state.session);
  if (!restoreSessionFinished) {
    yield take((innerAction) => (
      innerAction.type === SessionActions.restoreSessionDone().type
      || innerAction.type === SessionActions.restoreSessionError().type
    ));
  }
  const profile = yield select((state) => state.profile);
  if (profile.loggedIn) {
    yield put(NetworkMembershipActions.getCourseOffers(params?.id));
  }
}

function* mySGFavorites() {
  yield put(FavoriteCitiesActions.getFavoriteCities());
  yield put(FavoriteCoursesActions.getFavoriteCourses());
}

function* networkMembershipBenefits(action) {
  const { queryParams, params } = getQsAndParams(
    action.payload.location,
    NETWORK_MEMBERSHIP_BENEFITS,
  );
  if (queryParams?.crossPressed) return;
  const { courseId } = params || {};
  if (!courseId) {
    yield put(replace(NOT_FOUND));
    return;
  }
  yield put(NetworkMembershipActions.getNetworkMembershipBenefits({ courseId }));
}

function* networkMembershipCourses() {
  yield put(NetworkMembershipActions.getNetworkMembershipCourses());
}

function* networkMembershipSales() {
  const { isFetchingStatusDone } = yield select((state) => state.site);
  if (!isFetchingStatusDone) {
    yield take((innerAction) => (
      innerAction.type === SiteActions.fetchSiteStatusDone().type
      || innerAction.type === SiteActions.fetchSiteStatusError().type
    ));
  }
  const { networkMembershipEnabled } = yield select((state) => state.site?.data);
  if (!networkMembershipEnabled && !features.showNetworkSales) {
    yield put(replace(NOT_FOUND));
    return;
  }
  yield put(NetworkMembershipActions.getNetworkMembershipPlans());

  yield put(NetworkMembershipActions.getGolfClubCourses(false));
  yield put(PartnerOffersActions.getPartnerVendors(true));
}

function* golfVantagePromotion() {
  const { isFetchingStatusDone } = yield select((state) => state.site);
  if (!isFetchingStatusDone) {
    yield take((innerAction) => (
      innerAction.type === SiteActions.fetchSiteStatusDone().type
      || innerAction.type === SiteActions.fetchSiteStatusError().type
    ));
  }
  const { networkMembershipEnabled } = yield select((state) => state.site?.data);
  if (!networkMembershipEnabled) {
    yield put(replace(NOT_FOUND));
    return;
  }
  yield put(NetworkMembershipActions.getNetworkMembershipPlans());
  yield take((innerAction) => (
    innerAction.type === LocationActions.getLocationDone().type
    || innerAction.type === LocationActions.getLocationError().type
  ));
}

function* partnerVendors() {
  const { isFetchingStatusDone } = yield select((state) => state.site);
  if (!isFetchingStatusDone) {
    yield take((innerAction) => (
      innerAction.type === SiteActions.fetchSiteStatusDone().type
      || innerAction.type === SiteActions.fetchSiteStatusError().type
    ));
  }
  const { networkMembershipEnabled } = yield select((state) => state.site?.data);
  if (!networkMembershipEnabled) {
    yield put(replace(NOT_FOUND));
    return;
  }
  yield put(PartnerOffersActions.getPartnerVendors());
  yield take((innerAction) => (
    innerAction.type === LocationActions.getLocationDone().type
    || innerAction.type === LocationActions.getLocationError().type
  ));
}

function* networkMembershipPlans(action) {
  yield put(NetworkMembershipActions.setStatus(''));

  const { isFetchingStatusDone } = yield select((state) => state.site);
  if (!isFetchingStatusDone) {
    yield take((innerAction) => (
      innerAction.type === SiteActions.fetchSiteStatusDone().type
      || innerAction.type === SiteActions.fetchSiteStatusError().type
    ));
  }
  const { networkMembershipEnabled } = yield select((state) => state.site?.data);
  if (!networkMembershipEnabled && !features.showNetworkSales) {
    yield put(replace(NOT_FOUND));
    return;
  }

  const { restoreSessionFinished } = yield select((state) => state.session);
  if (!restoreSessionFinished) {
    yield take((innerAction) => (
      innerAction.type === SessionActions.restoreSessionDone().type
      || innerAction.type === SessionActions.restoreSessionError().type
    ));
  }

  const location = yield select((state) => state.router.location);
  const user = yield select((state) => state?.profile);

  if (action.payload.location.pathname === NETWORK_MEMBERSHIP_SALES) {
    yield put(NetworkMembershipActions.getNetworkMembershipPlans());
    if (user?.hasNetworkMembership) {
      yield put(push(MY_MEMBERSHIP));
    }
    return;
  }

  const goToSignIn = yield goToSignInHandler({
    path: `${location.pathname}${location.search}`,
    newWindow: false,
    trackEvent: null,
  });
  if (goToSignIn) return;

  if (user?.hasNetworkMembership) {
    yield put(push(MY_MEMBERSHIP));
  }

  const { queryParams } = getQsAndParams(action.payload.location, NETWORK_MEMBERSHIP_JOIN);
  yield put(NetworkMembershipActions.getNetworkMembershipPlans());
  yield race([
    take(NetworkMembershipActions.getNetworkMembershipPlansDone().type),
    take(NetworkMembershipActions.getNetworkMembershipPlansError().type),
  ]);

  const plans = yield select((state) => state.networkMembership.golfClubPlans);
  const plan = queryParams?.planId ? plans.find((p) => p.id === Number(queryParams?.planId)) : null;
  if (!plan) {
    const interval = queryParams?.interval || 'monthly';
    const planId = plans.find((p) => p.interval === interval).id;
    yield put(replace(`${NETWORK_MEMBERSHIP_JOIN}?planId=${planId}`));
    return;
  }

  yield put(NetworkMembershipActions.trackNetworkMembershipPurchase(plan.id));

  if (location?.query?.donePressed !== 'true') {
    yield put(CreditCardsActions.getCreditCards());
    yield race([
      take(CreditCardsActions.getCreditCardsDone().type),
      take(CreditCardsActions.getCreditCardsError().type),
    ]);
    const { creditCards } = yield select((state) => state.creditCards);
    if (!creditCards.length) return;
    const defaultCard = creditCards.filter((card) => card.isDefault);
    const seletedCard = defaultCard.length ? defaultCard[0] : creditCards[0];
    yield put(NetworkMembershipActions.setNetworkMembershipCreditCard(seletedCard));
  }
}

function* newCourseReviews(action) {
  const { queryParams, params } = getQsAndParams(action.payload.location, NEW_COURSE_REVIEW);

  const { isBanned } = yield select(({ profile }) => profile);
  if (isBanned) {
    const path = COURSE_REVIEWS.replace('/:countryId/:stateId/:cityId/:courseId', `/${params.countryId}/${params.stateId}/${params.cityId}/${params.courseId}`);
    yield put(push(`${path}${action.payload.location.search}`));
    yield put(AlertActions.displayAlert('Error', BANNED_ERROR_MESSAGE));
    return;
  }

  const {
    cart,
    date,
    dealsOnly,
    holes,
    location,
    locationRange,
    players,
    previousRateTypes,
    price,
    rate,
    rateTypes,
    time,
    timeSlot,
  } = parseSearchParams(queryParams);

  const dateValue = DateHelper.getDateFromPathWithDefault(date, DateHelper.DEFAULT_FORMAT).toDate();

  yield put(SearchParamsActions.setValues({
    ...pickBy({ location: { ...location } }),
    date: dateValue,
    players,
    holes,
  }, {
    cart,
    dateFilter: dateValue,
    dealsOnly,
    holesFilter: holes,
    locationFilter: location,
    locationRange,
    playersFilter: players,
    previousRateTypes,
    price,
    rate,
    rateTypes,
    time,
    timeSlot,
  }));
  const { courseId: paramSlug } = params;

  yield put(CourseDetailActions.getCourseDetail(paramSlug));
}

function* loadDealDetail() {
  const { isDone } = yield select((state) => state.location);
  if (!isDone) {
    yield take((innerAction) => (
      innerAction.type === LocationActions.getLocationDone().type
      || innerAction.type === LocationActions.getLocationError().type
    ));
  }
  const location = yield select((state) => state.location);
  if (!location.lat || !location.lon) {
    yield put(push(HOME));
    return;
  }
  yield put(SearchParamsActions.setLocationFilter({
    ...CURRENT_LOCATION,
    type: 'City',
    hierarchizedUrl: location.hierarchizedUrl,
    latitude: location.lat,
    longitude: location.lon,
  }));
  yield put(SearchParamsActions.setDealsOnlyOn(['is_lightning_rate']));
  yield put(SearchFiltersActions.setSortByCourses('price-lowest-first'));
  yield put(SearchParamsActions.performSearch('City'));
  yield put(NavbarActions.setActiveNavItem('deals'));
}

function isUnknownLocation(action) {
  return action.payload.location.pathname.includes('unknown');
}

function* handleUnknownLocation() {
  yield delay(100);
  yield put(replace(NOT_FOUND));
}

function* dummy() {
  yield Promise.resolve();
}

function* login(action) {
  const urlParams = new URLSearchParams(action.payload.location.search);
  const provider = urlParams.get('provider');
  const reset = urlParams.get('reset');

  const { restoreSessionFinished } = yield select((state) => state.session);
  if (!restoreSessionFinished) {
    yield take((innerAction) => (
      innerAction.type === SessionActions.restoreSessionDone().type
      || innerAction.type === SessionActions.restoreSessionError().type
    ));
  }
  yield put(SessionActions.redirectIfSession(HOME));
  yield put(RegistrationActions.resetRegistrations());

  if (reset === 'true') {
    yield put(push('/sign-in'));
    window.location.reload();
  }

  if (provider === 'apple') {
    const appleCode = urlParams.get('apple_code');
    const path = urlParams.get('path');

    if (appleCode) {
      yield put(
        RegistrationActions.socialSignIn(provider, { appleCode, path }),
      );
    } else {
      yield dummy();
    }
  }
}

function* sgClub(action) {
  const urlParams = new URLSearchParams(action.payload.location.search);

  const redirectUrl = urlParams.get('redirect_url');
  if (redirectUrl) {
    yield put(MembershipActions.setMembershipRedirectTo(decodeURIComponent(redirectUrl)));
  } else {
    yield dummy();
  }

  const {
    membership: {
      billingPlanId,
      membershipId,
    } = {},
  } = yield select((state) => state.membership);

  if (!billingPlanId && !membershipId) {
    yield put(MembershipActions.setProgramData());

    yield race([
      take(MembershipActions.setProgramDataDone().type),
      take(MembershipActions.setProgramDataError().type),
    ]);
  }
}

function* mySgClub() {
  yield put(MembershipDetailsActions.getMembershipDetails());
  yield put(MembershipActions.setProgramData());
  yield put(MembershipDetailsActions.getPaymentsHistory());
}

function* priceAlerts() {
  yield put(PriceAlertsActions.getPriceAlerts());
  yield put(MembershipActions.setProgramData());
}

function* home() {
  yield put(NavbarActions.setActiveNavItem('search'));
  yield put(BannersActions.setBanners());
  yield put(ContestActions.getContest());
  yield put(CourseSuggestions.getCoursesNearYou());
  const { hasNetworkMembership } = yield select((state) => state.profile);
  if (!hasNetworkMembership) {
    yield put(NetworkMembershipActions.getGolfClubCourses(false, true));
  }
  yield put(SearchParamsActions.hardResetFilters());
  yield take((innerAction) => (
    innerAction.type === LocationActions.getLocationDone().type
      || innerAction.type === LocationActions.getLocationError().type
  ));
}

function* verifyCustomerDetails() {
  const { loggedIn } = yield select(({ profile }) => profile);

  if (!loggedIn) {
    yield put(replace(NOT_FOUND));
  }
}

function* goBackToMyAccountAfterLogin(action) {
  const { restoreSessionFinished } = yield select((state) => state.session);
  if (!restoreSessionFinished) {
    yield take((innerAction) => (
      innerAction.type === SessionActions.restoreSessionDone().type
      || innerAction.type === SessionActions.restoreSessionError().type
    ));
  }

  return yield goToSignInHandler({
    path: action.payload.location.pathname,
    newWindow: false,
    trackEvent: null,
  });
}

export function* navigationLoader(action) {
  yield* trackPageView(action);
  yield put({ type: 'RESET' });

  if (!sessionStorage.networkMembershipEnabled
    || !sessionStorage.siteStatusExp
    || (sessionStorage.siteStatusExp - Date.now() < 0)
  ) {
    yield put(SiteActions.fetchSiteStatus());
  } else {
    yield put(SiteActions.fetchSiteStatusDone({
      networkMembershipEnabled: sessionStorage.networkMembershipEnabled === '1',
    }));
  }

  if (action.type === '@@router/LOCATION_CHANGE'
    && action.payload.location.pathname.includes(MY_SG_PREFIX)) {
    return yield put(
      replace(action.payload.location.pathname.replace(MY_SG_PREFIX, MY_ACCOUNT_PREFIX)),
    );
  }

  if (onRoute(WELCOME_TO_SUPREME)(action)) {
    return yield put(WelcomeActions.welcome());
  }

  if (onRoute(WEB_VIEW_DONE)(action)) {
    yield* dummy();
  }
  if (onRoute(ANONYMOUS_PURCHASE)(action)) {
    yield* dummy();
  }

  if (onRoute(HOME)(action)) {
    yield* home();
  }

  if (onRoute(ABOUT)(action)) return yield* dummy();
  if (onRoute(BOOKING_CONFIRMATION)(action)) {
    return yield* bookingConfirmation(action);
  }
  if (onRoute(BOOKINGS_INVITE)(action)) {
    return yield* bookingsInvite(action);
  }
  if (onRoute(BOOKING_OPTIONS)(action)) return yield* dummy();

  if (onRoute(COURSE_REVIEWS)(action)) {
    if (!features.courseReviews) {
      return yield handleUnknownLocation();
    }
    const { pathname, search } = action.payload.location;
    const REVIEWS_URL = `${pathname.replace('reviews', 'details')}${search ? `${search}&tab=reviews` : '?tab=reviews'}`;
    yield put(push(REVIEWS_URL));
  }

  if (onRoute(COURSE_REVIEW)(action)) {
    if (!features.courseReviews) {
      return yield handleUnknownLocation();
    }
    yield* courseReview(action);
  }

  if (onRoute(DEALS)(action)) {
    if (!features.dealsPage) {
      return yield handleUnknownLocation();
    }
    yield* loadDealDetail(action);
  }

  if (onRoute(FAQS)(action)) {
    return yield put(FaqsActions.getFaqs());
  }

  if (onRoute(UNLOCK)(action)) {
    return yield* unlock(action);
  }

  if (onRoute(MY_ACCOUNT_PREFIX)(action)) return yield* dummy();
  if (onRoute(PRIVACY_POLICY)(action)) return yield* dummy();
  if (onRoute(REQUEST_RESET_PASSWORD)(action)) return yield* dummy();
  if (onRoute(RESET_PASSWORD)(action)) return yield* dummy();

  if (onRoute(SIGN_IN)(action) || onRoute(SIGN_UP)(action)) {
    return yield* login(action);
  }

  if (onRoute(SUPPORT)(action)) return yield* dummy();

  if (onRoute(TEE_TIME_NOT_ALLOWED)(action)) {
    return yield* teeTimeNotAllowed(action);
  }

  if (onRoute(TEE_TIME_NOT_AVAILABLE)(action)) {
    return yield* teeTimeNotAvailable(action);
  }

  if (onRoute(TEE_TIME_ERROR)(action)) {
    return yield* teeTimeError(action);
  }

  if (onRoute(TERMS_AND_CONDITIONS)(action)) return yield* dummy();
  if (onRoute(ACCOUNT_SETTINGS)(action)) return yield* dummy();
  if (onRoute(ACCOUNT_SUMMARY)(action)) return yield* dummy();
  if (onRoute(GOLFER_STATS)(action)) return yield* dummy();
  if (onRoute(GOLFER_STATS)(action)) return yield* dummy();
  if (onRoute(FAVORITES)(action)) {
    return yield* mySGFavorites();
  }

  if (onRoute(MY_PROFILE)(action)) {
    return yield* getProfile();
  }

  if (onRoute(PAYMENT_METHODS)(action)) {
    return yield put(CreditCardsActions.getCreditCards());
  }

  if (onRoute(PARTNER_OFFERS)(action)) {
    return yield put(PartnerOffersActions.getPartnerOffers());
  }

  if (onRoute(PRICE_ALERTS)(action)) {
    return yield* priceAlerts(action);
  }

  if (onRoute(COMMUNICATION_PREFERENCES)(action)) {
    return yield* goBackToMyAccountAfterLogin(action);
  }

  if (onRoute(MESSAGE_PREFERENCES)(action)) {
    return yield* goBackToMyAccountAfterLogin(action);
  }

  if (onRoute(REWARDS)(action)) {
    if (!features.rewardsAccumulation) {
      return yield handleUnknownLocation();
    }
    return yield put(RewardsActions.getRewardsProfileUrl());
  }

  if (onRoute(NETWORK_MEMBERSHIP_BENEFITS)(action)) {
    if (isMobileScreen()) return yield* networkMembershipBenefits(action);
    return yield handleUnknownLocation();
  }

  if (onRoute(NETWORK_MEMBERSHIP_COURSES)(action)) {
    return yield* networkMembershipCourses(action);
  }

  if (onRoute(NETWORK_PARTICIPATING_COURSES)(action)) {
    return yield* networkMembershipCourses(action);
  }

  if (onRoute(NETWORK_MEMBERSHIP_SALES)(action)) {
    if (features.networkMembership || features.showNetworkSales) {
      return yield* networkMembershipSales(action);
    }
    return yield handleUnknownLocation();
  }

  if (onRoute(GOLF_VANTAGE_PROMOTION)(action)) {
    if (features.networkMembership) {
      return yield* golfVantagePromotion(action);
    }
    return yield handleUnknownLocation();
  }

  if (onRoute(NETWORK_PARTICIPATING_PARTNERS)(action)) {
    if (features.networkMembership) {
      return yield* partnerVendors(action);
    }
    return yield handleUnknownLocation();
  }

  if (onRoute(NETWORK_MEMBERSHIP_JOIN)(action)) {
    if (features.networkMembership || features.showNetworkSales) {
      return yield* networkMembershipPlans(action);
    }
  }

  if (onRoute(NEW_COURSE_REVIEW)(action)) {
    if (!features.courseReviews) {
      return yield handleUnknownLocation();
    }
    return yield* newCourseReviews(action);
  }

  if (onRoute(NEW_PAYMENT_METHOD)(action)) return yield* dummy();

  if (onRoute(DEAL)(action)) {
    if (!features.dealsPage) {
      return yield handleUnknownLocation();
    }
    return yield* loadDealDetail(action);
  }

  if (onRoute(EXPLORE_COUNTRY)(action)) {
    if (action.payload.location.pathname === '/explore/currentlocation') {
      return yield currentLocation();
    }
    if (isUnknownLocation(action) || !features.exploreCountriesStates) {
      return yield handleUnknownLocation();
    }

    const { params } = getQsAndParams(action.payload.location, EXPLORE_COUNTRY);
    return yield put(ExploreActions.getInitialData(params));
  }

  if (onRoute(EXPLORE_STATE)(action)) {
    if (isUnknownLocation(action) || !features.exploreCountriesStates) {
      return yield handleUnknownLocation();
    }

    const { params } = getQsAndParams(action.payload.location, EXPLORE_STATE);
    return yield put(ExploreActions.getInitialData(params));
  }

  if (onRoute(BOOKING_CONFIRMATION_AT)(action)) {
    yield put(FlowActions.setfBookingConfirmation({ URL: window.location.href }));
    return yield* bookingConfirmationAtCourse(action);
  }

  if (onRoute(BOOKINGS)(action)) {
    return yield* loadUserReservations(action);
  }

  if (onRoute(CHECKOUT)(action)) {
    return yield* checkout(action);
  }

  if (onRoute(RATE_TYPES)(action)) {
    return yield* searchRateTypes(action);
  }

  if (onRoute(MEMBERSHIP_UPSELL)(action)) {
    return yield* searchMembership(action);
  }

  if (onRoute(SELECT_PROVIDER)(action)) {
    return yield* searchProviders(action);
  }

  if (onRoute(CITY_SEARCH_RESULT)(action)) {
    yield put(FlowActions.setfCourses({ URL: window.location.href }));
    return yield* searchCourses(action);
  }

  if (onRoute(BOOKINGS_ID)(action)) {
    return yield* loadUserReservation(action);
  }

  if (onRoute(COURSE_SEARCH_RESULT)(action)) {
    yield put(FlowActions.setfTeeTimes({ URL: window.location.href }));
    return yield* searchTimeSlots(action);
  }

  if (onRoute(COURSE_DETAILS)(action)) {
    return yield* courseDetails(action);
  }

  if (onRoute(SG_CLUB)(action)) {
    return yield* sgClub(action);
  }

  if (onRoute(MY_MEMBERSHIP)(action)) {
    if (features.networkMembership) {
      return yield myMembership();
    }
    return yield handleUnknownLocation();
  }

  if (onRoute(NETWORK_OFFERS)(action)) {
    if (features.networkMembership) {
      return yield networkOffers(action);
    }
    return yield handleUnknownLocation();
  }

  if (onRoute(NETWORK_OFFERS_REDEEM)(action)) {
    if (features.networkMembership) {
      return yield networkOffersRedeem(action);
    }
    return yield handleUnknownLocation();
  }

  if (onRoute(MY_SG_CLUB)(action)) {
    return yield* mySgClub();
  }

  if (onRoute(MODERATOR_REVIEWS)(action)) {
    if (!features.moderator) {
      return yield handleUnknownLocation();
    }

    return yield* loadModerateContent(action);
  }

  if (onRoute(UNLOCK_ACCOUNT_FAILED)(action)) return yield* dummy();

  if (onRoute(SECTION_COURSES)(action)) {
    if (!features.sectionCourses) {
      return yield handleUnknownLocation();
    }

    return yield* searchSectionCourses(action);
  }

  if (onRoute(UNSUBSCRIBE)(action)) {
    return yield* loadUnsubscribe(action);
  }

  if (onRoute(UNSUBSCRIBE_PRE_USERS)(action)) {
    return yield* loadUnsubscribePreUser(action);
  }

  if (onRoute(VERIFY_CUSTOMER_DETAILS)(action)) {
    return yield* verifyCustomerDetails(action);
  }

  if (onRoute(DELETE_ACCOUNT)(action)) {
    return yield* goBackToMyAccountAfterLogin(action);
  }

  if (onRoute(CONFIRM_DELETION)(action)) {
    return yield* goBackToMyAccountAfterLogin(action);
  }

  return yield* dummy();
}

function* navigationWatcher() {
  yield takeLatest('@@router/LOCATION_CHANGE', navigationLoader);
}

export default [
  navigationWatcher,
];
